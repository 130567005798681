@import '~react-datepicker/dist/react-datepicker.css';

.input-field {
  width: 70%;
  border: none;
  outline: none;
  z-index: 0 !important;
  background-color: transparent;
}

.calendar-container {
  position: relative;
  height: 60;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  background-color: #fff;
  border-radius: 3px !important;
  box-sizing: border-box;
  border: 1px solid #c8c8c8;
  padding: 0.5rem;
  height: 38px;
}

.calendar-label {
  margin-top: -2px;
  padding-bottom: 5px;
}
