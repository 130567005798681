@use "../../assets/styles/colors";

.container {
  margin: 0 auto;
  max-width: 780px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.secondaryContainer {
  margin: 0 auto;
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.halfWidth {
  max-width: 780px !important;
}

.span {
  color: #6c6c6c;
}

.title {
  font-size: 20px;
  color: #EF4066;
  margin: 0;
  margin-bottom: 5px;
  margin-top: 28px;
  align-self: baseline;
}

.subtitle {
  font-size: 14px;
}

.container span {
  font-size: 14px;
}

.titleContainer {
  align-self: baseline;
}

.selectsContainer {
  margin-top: 15px;
  margin-left: 15px;
}

.customButton {
  background-color: #8ac64b;
  color: white;
  border-radius: 3px;
  padding: 0.9em 2em !important;
  margin: 0 !important;
}

.customButton:disabled {
  display: flex !important;
  background-color: #ccc;
}

.customButton:disabled:hover {
  display: flex !important;
  background-color: #aaa;
}

.customButton:hover {
  background-color: #62974b;
  transition: all 300ms ease-in-out;
}

.individualSelectContainer {
  display: flex;
  align-items: center;
  padding-bottom: 17px;
}

.individualSelectContainer:last-of-type {
  margin-bottom: 15px;
}

.individualSelectContainer span {
  line-height: 18px;
  margin: 0px 0 5px 0px;
}

.individualSelectContainer label {
  margin: 0 20px 0 0;
}

.requiredFieldsHint {
  font-style: italic;
  color: #EF4066 !important;
  margin-left: 36px;
}

.buttonArea {
  margin-top: 44px;
}

.pdfAnchor {
  display: inline-block;
  border-bottom: 1px solid #c2baa3;
  padding-bottom: 3px;
  cursor: pointer;
  font-weight: bold;
}

.contratoActionsContainer {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  justify-content: center;
  align-items: center;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
  }
}

.contractIconWrapper {
  padding: 40px 34px 40px 45px;
  border-radius: 8px;
  border: 1px solid #edf1f7;
  background: #f8f8fb;
}

.contractButtonsContainer {
  flex-direction: column;
  margin-left: 20px;

  @media (max-width: 760px) {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.customOutline {
  padding: 0.5rem 2rem;
  color: #EF4066;
  font-size: 14px;
  font-weight: normal;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid #EF4066;
  height: 32px;
  width: 155px;
  margin: 0;
  margin-bottom: 12px;
}

.customFilled {
  padding: 0.5rem 2rem;
  background: #EF4066;
  border-radius: 3px;
  color: #fff;
  font-weight: normal;
  height: 32px;
  width: 155px;
  font-size: 14px;
  margin: 0;
}

.clickSignInfoWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  margin-top: 60px;
}

.clickSignInfoWrapper .span {
  margin-right: 6px;
}

.clickSignContractWrapper {
  width: 1100px;

  @media (max-width: 760px) {
    width: 92vw;
  }
}

.contratoHeaderContainer {
  width: 100%;
}

.planSelectSection {
  display: flex;
  flex-direction: row;
  max-width: 780px;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  margin-top: 40px;
  margin-bottom: 30px;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px 0;
  }
}

.planSelectSection > div {
  flex: 1;
}

.planSelectContainer {
  width: 100%;
}

.choosePlanButtonWrapper {
  @media (max-width: 760px) {
    padding-bottom: 14px;
  }
}

.planDetailsText {
  color: #812990;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 38px;
  margin-left: 27px;

  @media (max-width: 760px) {
    margin-left: 0px;
    margin-top: 15px;
  }
}

.buttonDisabled {
  background-color: #ccc;
  cursor: default;
}

.buttonDisabled:hover {
  background-color: #ccc;
}

.switchContainer {
  margin-bottom: 45px;
}

.typePlan {
  margin-right: 10px;
  font-size: 14px;
  line-height: 17px;
  color: #4b4b4b;
}

.switchPlan {
  display: flex;
  align-items: center;
  padding: 10px;

  @media (max-width: 760px) {
    justify-content: center;
    padding: 0px;
    margin-top: 20px;
  }
}

.subTitlePlan {
  color: colors.$color-primary;
  font-size: 14px;
}

.especificPlanDescription {
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
}
