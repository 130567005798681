@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #f8f8fb !important;
}

.pageWrapper {
  min-height: 100vh;
  margin-top: -26px;
}

.pageTitle {
  margin: 0;
  color: #152d45;
  font-family: fonts.$font-regular;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  padding-top: 48px;
  margin-bottom: 24px;
}

.panel {
  position: relative;
  background-image: url("../../assets/img/checkout-header.png");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";

  div {
    max-width: 526px;
  }

  .panelContentContainer {
    width: 100%;
    -moz-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;
    filter: FlipH;
    -ms-filter: "FlipH";

    @media (max-width: 760px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 18px 0 62px;

  .sponsorContainer {
    -moz-transform: scaleX(-1) !important;
    -o-transform: scaleX(-1) !important;
    -webkit-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;
    filter: FlipH;
    -ms-filter: "FlipH";

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: absolute;
    left: 18px;
    bottom: 8px;

    p {
      font-size: 10px;
      color: #fff;
      font-weight: 400;
    }
  }

  @media (max-width: 700px) {
    background-image: url("../../assets/img/checkout-mobile.png");
  }
}

.logoWrapper {
  display: flex;
  text-align: center;
  justify-content: space-between;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.infoWrapper {
  margin-top: 12px;
  text-align: center;
}

.formContentWrapper {
  max-width: 526px;
  margin: 0 auto;
  position: relative;
  margin-top: -120px;

  h2 {
    text-align: center;
    margin-top: 68px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #152d45;
  }

  @media (max-width: 700px) {
    max-width: 365px;
  }
}

.initialForm {
  padding: 45px;
  margin-top: 0px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 20px 20px rgba(209, 209, 209, 0.15);
  border-radius: 6px;

  fieldset {
    margin-bottom: 20px;
  }

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 39px;
    color: #152d45;
  }
}

.infoWrapper h2 {
  margin-top: 40px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 80px;
  color: #ffffff;
}

.paymentInfo {
  display: flex;
  flex-direction: column;
}

.paymentTitle {
  display: flex;
  align-items: center;

  font-size: 24px;
  color: colors.$color-primary;
  font-family: fonts.$font-regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #152d45;
  margin-top: 50px;
  line-height: 28.8px;

  svg {
    margin-right: 10px;
  }
}

.creditCardChoice {
  margin-top: 30px;
  background: #f8f8fb;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 74px !important;
}

.paymentOptionsContainer {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    cursor: pointer;
    background: #fff;
    width: 232px;
    height: 92px;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    filter: drop-shadow(0px 20px 20px rgba(209, 209, 209, 0.15));
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    color: #4a545b;
    outline: none;
    transition: 0.3s;

    svg {
      margin-right: 10px;
    }
  }

  .activeButtonPayment {
    transition: 0.3s;
    background: linear-gradient(90deg, #f2709c 0%, #ff9472 100%);
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    box-shadow: 0px 20px 20px rgba(209, 209, 209, 0.15);
    border-radius: 6px;
    color: #ffffff;
    outline: none;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;

    button {
      margin-bottom: 20px;
    }
  }
}

.flexCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.creditCard .checkboxLabel,
.debitCard .checkboxLabel {
  font-size: 14px;
  line-height: 17px;

  margin-bottom: 0 !important;
  margin-right: 16px !important;

  color: #152d45;
}

.paymentForm {
  display: grid;
  grid-gap: 1.063rem;
  margin: 32px 0 16px;
}

.flexColumn {
  flex-direction: column;
}

.gridFormCard {
}

.cardNumber {
  grid-area: number;
}

.cardName {
  grid-area: name;
}

.cardValidity {
  grid-area: validity;
}

.cardSecurityCode {
  grid-area: securityCode;
}

.buttonArea {
  margin: 0;
  margin-top: 38px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
    background: linear-gradient(90deg, #7474bf 0%, #348ac7 100%);
    height: 50px;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: bold;
  }
}

.footerInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;

  img {
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #4b4b4b;
  }
}

.payment {
  // border-bottom: 2px dotted #EF4066;
  padding-bottom: 80px;
  position: relative;
}

.scissorsIcon {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translateY(calc(-100% + 4px));
}

.contentContainer {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  margin: 2%;
  padding: 2%;
}

.contentContainerHeading {
  font-size: 2.188rem;
  font-family: fonts.$font-thin;
  color: colors.$color-secondary;
  text-align: center;
  height: 68px;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.planSelectWrapper {
  width: 80%;
  margin: 0 auto;

  select {
    z-index: 999999;
  }
  option {
    z-index: 999999;
  }
  div {
    z-index: 999999;
  }

  label {
    color: colors.$color-secondary;
  }

  margin-bottom: 22px;
}

.formWrapper {
  margin-top: 500px;
  border-bottom: 2px dotted colors.$color-primary;
  padding-bottom: 38px;

  form {
    background-color: #f8f8f8;
    padding: 42px 0;
    margin-bottom: 38px;

    fieldset {
      margin-top: 18px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.hintText {
  font-size: 0.875rem;
  color: #6c6c6c;
}

.invoicePaymentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 36px 0 55px;

  button {
    border-radius: 5px;
    font-size: 0.875rem;
    font-family: fonts.$font-regular;
    font-weight: 400;
    width: 100%;
    height: 51px;
  }
}

.invoicePaymentContainerHeading {
  margin: 0;

  font-family: fonts.$font-regular;
  font-weight: 400;
  font-size: 20px;
  color: colors.$color-primary;
  text-align: center;

  margin-bottom: 28px;
}

.invoicePaymentDescription {
  margin: 0;
  font-size: 0.75rem;
  color: colors.$color-primary;
  text-align: center;
  padding: 0 20%;
  font-family: fonts.$font-regular;
  font-weight: 400;
}

.copyToClipboardSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 47px;

  .buttonWrapper {
    width: 117px;
    height: 42px;
    border-radius: 6px;
    padding: 1px;
    background: linear-gradient(90deg, #f2709c 0%, #ff9472 100%);
    transition: 0.3s;

    button {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      color: #812990;
      background: #f7f9f9;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
      transition: 0.3s;

      img {
        margin-right: 10px;
      }
    }

    &:hover {
      filter: drop-shadow(0px 20px 20px rgba(209, 209, 209, 0.4));

      button {
        background: #fff;
      }
    }
  }

  & > p {
    font-size: 1rem;
    color: colors.$color-primary;
    margin-bottom: 12px;
  }
}

.digitableLine {
  padding: 12px 14px;
  color: #4a545b;
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid #e8ebf1;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;

  p {
    word-wrap: break-word;
    text-align: center;
    margin: 0;
  }

  margin-bottom: 20px;
}

.invoiceInfoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.invoiceInfoWrapper {
  border-bottom: 2px dotted colors.$color-primary;
  width: 100%;
  padding: 0 15%;
  margin-bottom: 30px;
}

.invoiceInfoSectionFieldset {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #4a545b;

  &:last-of-type {
    margin-bottom: 33px;
  }
}

.successDialog {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 70px 10px !important;

  button {
    width: 510px;
    color: #fff;
    border-radius: 6px;
  }

  span {
    max-width: 488px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 20px;

    color: #4b4b4b;
  }

  p {
    font-weight: 400;
    color: #fff;
    max-width: 565px;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    margin-top: 25px;

    color: #4b4b4b;
  }
}

.closeIconWrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.gridItem {
  height: 74px !important;
  max-width: 33.333333% !important;
  flex-basis: 33.333333% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.gridItemCard {
  height: 74px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.resetMargin {
  margin: 0 !important;
}
