@use "../../../assets/styles/colors";

.cadastro-switch-area {  
  padding-left: 10px;
}

.cadastro-first-title {  
  color: colors.$color-secondary;
  font-size: 1rem;
  line-height: 19px;
  margin-bottom: 16px;
}

.cadastro-font-active {  
  color: colors.$color-secondary;
  font-weight: 700;
  font-size: 14px;  
}

.cadastro-font-inactive {  
  color: colors.$color-text-secundary;
  font-weight: 700;
  font-size: 14px;  
}

.cadastro-switch-accept {  
  margin-left: -12px !important;
}