@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

:root {
  --header-sidebar-user-height: 5rem;

  --header-sidebar-user-container-second-color: #f3f3f3;

  --header-sidebar-user-text-font-size: 90%;
  --header-sidebar-user-text-line-height: 14px;
  --header-sidebar-user-text-letter-spacing: -0.5px;
  --header-sidebar-user-text-color: #6c6c6c;

  --header-sidebar-user-icon-width: 41px;
  --header-sidebar-user-icon-height: 41px;
  --header-sidebar-user-icon-border-radius: 50%;

  --header-sidebar-user-options-border: 1px solid #e7e8ec;
  --header-sidebar-user-options-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}

@media (max-width: 1100px) {
  :root {
    --header-sidebar-user-height: 3rem;
  }
}

.avatar-usuario {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}

.header-sidebar-user {
  width: 100%;
  height: var(--header-sidebar-user-height);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.header-sidebar-user-container {
  width: 100%;
  height: 100%;
  padding: 0 19px 0 19.5px;
  display: inherit;
  align-items: inherit;
  justify-content: space-between;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .header-sidebar-user-container {
    position: relative;
    top: -30px;
  }
}

.header-sidebar-user-container:hover {
  background-color: var(--header-sidebar-user-container-second-color);
}
.header-sidebar-user-icon {
  display: flex;
  color: colors.$color-white;
  align-items: center;
  justify-content: center;
  width: var(--header-sidebar-user-icon-width);
  height: var(--header-sidebar-user-icon-height);
  border-radius: var(--header-sidebar-user-icon-border-radius);
  &:not(.avatar-usuario-container) {
    background-color: colors.$color-secondary;
  }
}
.header-sidebar-user-name {
  color: var(--header-sidebar-user-text-color);
  font-family: fonts.$font-regular;
  line-height: var(--header-sidebar-user-text-line-height);
  font-size: var(--header-sidebar-user-text-font-size);
  letter-spacing: var(--header-sidebar-user-text-letter-spacing);
  margin-left: 0.3rem;
  max-width: 72%;
}
.header-sidebar-user-wrap {
  display: flex;
  align-items: center;
}
.header-sidebar-user-options {
  position: absolute;
  left: 90%;
  top: 55%;
  width: 160px;
  border: var(--header-sidebar-user-options-border);
  background-color: colors.$color-white;
  border-radius: 3px;
  z-index: 11;
  box-shadow: var(--header-sidebar-user-options-shadow);
}

@media (max-width: 390px) {
  .header-sidebar-user-options {
    left: 48%;
  }
}

@media (min-width: 390px) and (max-width: 450px) {
  .header-sidebar-user-options {
    left: 55%;
  }
}

@media (min-width: 450px) and (max-width: 520px) {
  .header-sidebar-user-options {
    left: 60%;
  }
}

@media (min-width: 520px) and (max-width: 600px) {
  .header-sidebar-user-options {
    left: 65%;
  }
}

@media (min-width: 600px) and (max-width: 710px) {
  .header-sidebar-user-options {
    left: 70%;
  }
}

@media (min-width: 710px) and (max-width: 890px) {
  .header-sidebar-user-options {
    left: 75%;
  }
}

@media (min-width: 890px) and (max-width: 1110px) {
  .header-sidebar-user-options {
    left: 80%;
  }
}

.header-sidebar-user-options ul {
  list-style-type: none;
  color: #0a2346;
  margin: 0;
  padding: 0;
}
.header-sidebar-user-options ul li {
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-sidebar-user-options ul li:hover {
  background-color: #f3f3f3;
}
.header-sidebar-user-options ul li p {
  margin: 0 0 0 0.4rem;
  font-size: 14px;
}
.header-sidebar-user-options-logout {
  border-top: 1px solid #e7e8ec;
}
.header-sidebar-user-options-profile {
  a {
    display: flex;
    text-decoration: none;
    color: #0a2346;
  }
}
.hide {
  display: none;
}
.header-sidebar-user-collapse-icon-container {
  display: flex;
  margin: 0.2rem 0;
  box-sizing: border-box;
  background-color: var(--header-sidebar-user-container-second-color);
  justify-self: flex-end;
}
.header-sidebar-user-collapse-icon {
  -webkit-transition: -webkit-transform 0.1s ease-in-out;
  -ms-transition: -ms-transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
}
.header-sidebar-user-options-close {
  transform: rotate(-88deg);
  -ms-transform: rotate(-88deg);
  -webkit-transform: rotate(-88deg);
}
.header-sidebar-user-options-open {
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.header-sidebar-user-other-options {
  text-decoration: none;
  color: #0a2346;
}
