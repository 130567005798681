@use "../../assets/styles/colors";

:root {
  --img-logo: url("../../assets/img/logo001.svg");
  --img-logo2: url("../../assets/img/logo001.svg");
  --img-logoMobile: url("../../assets/img/logo001.svg");
  --img-logo-width: 258px;
  --img-logo-height: 103px;

  --description-color: #6c6c6c;
  --description-letter-spacing: 2%;
  --description-font-size: 20px;

  --login-form-container-color: #ffffff;

  --color-label: #ef4066;

  --input-font-family: "Raleway";
  --input-bg-color: #f5f5f5;
  --input-color-placeholder: #6c6c6c;

  --forgot-password-color: #6c6c6c;
}
.login-container {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: 1fr;
  height: 100vh;
}

.defaultTemplate {
  grid-template-columns: 50% 50% !important;
}

.login-background {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  background-position: center center;
}
.login-form-container {
  display: flex;
  background-color: var(--login-form-container-color);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}
.login-img-logo {
  width: var(--img-logo-width);
  height: var(--img-logo-height);
  background-image: var(--img-logo);
  background-repeat: no-repeat;
  background-size: contain;
}
.login-description-page {
  color: var(--description-color);
  letter-spacing: var(--description-letter-spacing);
  font-size: var(--description-font-size);
  text-align: center;
}
.hide {
  display: none;
}
.login-form {
  display: grid;
  width: 20.8rem;
  margin-top: 0rem;

  &.form-inactivity {
    display: block;
    text-align: center;

    .span-header {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      strong {
        color: #ef4066;
        white-space: nowrap;
      }
    }
    button {
      margin-top: 24px;
      width: 100%;
    }
  }
}
.login-form fieldset {
  margin: 1rem 0;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.login-form fieldset.hide {
  display: none;
}
.login-form fieldset label {
  cursor: pointer;
  padding: 0.8rem 0;
  color: var(--color-label);
}
.login-form fieldset input {
  width: 100%;
  background-color: var(--input-bg-color);
  font-family: var(--input-font-family);
  padding: 0 1rem;
  height: 50px;
  border: none;
  outline: none;
  box-sizing: border-box;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--input-color-placeholder);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--input-color-placeholder);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--input-color-placeholder);
}
.forgot-password {
  color: var(--forgot-password-color);
  cursor: pointer;
  font-size: 0.8rem;
}
.login-button {
  background-color: colors.$color-secondary;
  color: colors.$color-white;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  padding: 0.8rem 0;
  border: none;
  width: 100px;
  border-radius: 3px;
  transition: all 300ms ease-in-out;
}
.forgot-password-button {
  width: auto;
  padding: 0.8rem;
  justify-self: right;
}
.container-form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.only-button {
  justify-content: flex-end;
}
.login-button:hover,
.login-button:focus {
  background-color: colors.$color-primary;
}
@media (max-width: 1204px) {
  .login-container {
    grid-template-columns: 60% 40%;
  }
}
@media (max-width: 890px) {
  .login-container {
    background-image: url("../../assets/img/BG_login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .login-background {
    display: none;
  }
  .login-form-container {
    position: relative;
    border-radius: 3px;
    width: 70%;
    height: 50%;
  }
  .login-img-logo {
    background-image: var(--img-logo2);
    position: absolute;
    top: 10px;
  }
  .login-description-page {
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    margin-top: 200px;
  }
  .login-form {
    width: 80%;
  }
}
@media (max-width: 590px) {
  .login-form-container {
    width: 85vw;
    min-height: 20vh;
    height: auto;
    padding: 30px auto;
  }
  .login-form {
    width: 90%;
  }
  .container-form-footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .login-button {
    width: 100%;
  }
  .login-description-page {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .login-background {
    display: none;
  }
}
@media (max-height: 811px) {
  .login-form-container {
    min-height: 20vh;
  }
}
@media (max-height: 640px) {
  .login-form-container {
    min-height: 70vh;
  }
}
@media (max-height: 568px) {
  .login-form-container {
    min-height: 72vh;
  }
}

.login-img-mobile {
  display: flex;
  height: 90px;
  width: 171px;
  background-image: var(--img-logoMobile);
  background-repeat: no-repeat;
  background-size: contain;
}
