.form-table {
  max-width: 770px;
  margin: 0 auto;
}

.container {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0 !important;
  position: relative;
  padding: 1.5rem !important;
}

.container-conta {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0 !important;
  position: relative;
  padding: 1.5rem !important;
  min-height: 250px;
  height: auto;
}

.chat:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid white;
  right: 23px;
  top: -40px;
}

.chat:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  right: 50%;
  top: -18px;
}

.card-conta-container {
  padding: 14px 19px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  cursor: pointer !important;
}

.card-conta-container:last-of-type {
  margin-bottom: 0;
}

.card-conta-container h4 {
  margin: 0;
}

.card-conta-title {
  margin-bottom: 5px !important;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
}

.info-text {
  font-size: 14px;
}

.card-conta-saldo-container {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.light-style {
  background-color: #f8f8fb;
  border: 1px solid #edf1f7;
}

.light-style .card-conta-title {
  color: #EF4066;
}

.light-style .info-text {
  color: #4b4b4b;
}

.light-style .card-conta-saldo-container {
  color: #EF4066;
}

.dark-style {
  background: #053b59;
  border: 1px solid #edf1f7;
}

.dark-style .card-conta-title,
.dark-style .info-text {
  color: #fff;
}

.dark-style .card-conta-saldo-container {
  color: #812990;
}

.card-conta-info-wrapper {
  display: grid;
  column-gap: 74px;
  grid-template-areas:
    "agencia contacontabil"
    "contacorrente valorconciliar";
}

.card-conta-info-agencia {
  grid-area: agencia;
}

.card-conta-info-conta-contabil {
  grid-area: valorconciliar;
}

.card-conta-info-conta-corrente {
  grid-area: contacorrente;
}

.card-conta-info-valor-conciliar {
  grid-area: valorconciliar;
}

.container-contas-title {
  font-size: 14px;
  font-weight: 600;
  color: #EF4066;
  display: block;
  text-align: left;
  margin: 16px 0 8px;
}

.container-contas-cards-wrapper {
  margin-bottom: 20px;
}

.container-contas-saldo-wrapper {
  color: #EF4066;
  font-weight: 600;
  display: flex;
  justify-content: flex-end;
}

.container-contas-saldo-title {
  margin-right: 58px;
}

.container-contas-saldo-value {
}

.filter-component-container {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0 !important;
  position: relative;
  padding: 2rem 1.5rem !important;
  height: auto !important;
}

.container-conta-empty {
  height: auto !important;
  min-height: 0 !important;
  padding-bottom: 3rem !important;
}

.cell-valor {
  float: right;
  width: max-content;
  color: black;
}
