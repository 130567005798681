@use "../../assets/styles/colors";

.switch-container {
  background: #ececec;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  margin-right: 12px;
}

.switch-thumb {
  width: 11px !important;
  height: 11px !important;
  box-shadow: none !important;
  background-color: #6c6c6c !important;
}

.not-bool-switch-thumb {
  width: 11px !important;
  height: 11px !important;
  box-shadow: none !important;
  background-color: colors.$color-secondary !important;
}

.switch-track {
  background: #6c6c6c !important;
  width: 19px !important;
  height: 7px !important;
}

.not-bool-switch-track {
  background: colors.$color-secondary !important;
  width: 19px !important;
  height: 7px !important;
}

.switch-root {
  width: 43px !important;
  height: 28px !important;
  align-items: center !important;
}

.switch-switchBase {
  top: inherit !important;
}

.switch-checked {
  transform: translateX(15.5px) !important;
}

.switch-checked + .switch-track,
.switch-checked .switch-thumb {
  background: colors.$color-secondary !important;
}
