.modal-icms {  
  max-height: 500px !important;
  margin: auto !important;
}

.icms-modal-expand {  
  min-height: 250px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
 }
