.complementares-dialog-header {
  background-color: #fff;
  span {
    font-size: 16px;
    line-height: 19px;
    color: #EF4066;
  }
}

.complementares-dialog-content {
  padding: 24px 0;
}

.complementares-dialog-title {
  color: #053B59;
  font-size: 14px;
  line-height: 17px;
}

.complementares-dialog-text {
  color: #4B4B4B;
  font-size: 14px;
  line-height: 17px;
}
