@use "../../assets/styles/colors";

.novo-dependente {
  overflow-y: auto !important;
  max-height: min-content !important;
}

.dependente-font-decline {
  color: colors.$color-text-secundary;
  font-weight: 700;
  font-size: 14px;
}

.dependente-font-accept {
  color: colors.$color-warning;
  font-weight: 700;
  font-size: 14px;
  margin-left: -12px !important;
}
