@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.container {
  // margin-top: -38px !important;
  margin-right: 16px !important;
}

.search-container {
  display: block !important;
  align-items: flex-end;
  width: 100%;
}

.table-footer-submit {
  margin-top: -6rem;
  width: 100%;
}

.search-input {
  background-color: white !important;
  display: flex;
  max-height: 35px;
  height: 35px;
  max-width: 134px;
  padding: 6px 10px;
  margin-top: 20px !important;
  margin-bottom: 12px !important;
  margin: 0px;
  align-items: center;
  border-radius: 3px;

  @media screen and (max-width: 1100px) {
    max-width: unset;
    width: 100%;
    box-sizing: border-box;
  }
}

.search-input:focus {
  border: 1px solid #e8ebf1;
}

.search-icon {
  color: colors.$color-secondary;
}

.search-input-grid-container {
  max-width: 200% !important;
}

.search-input-input-container {
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
}

.search-input input {
  max-width: 70%;
  border: 0;
  outline: 0;
}

.search-input input::placeholder {
  font-size: 13px;
  color: #555;
  line-height: 15px;
}

.table-perpage-wrapper {
  padding-left: 16px;
  height: 140px;
}

.MuiTableContainer-root {
  overflow-y: hidden;
  overflow-x: hidden ;
  min-height: 450px;
  background-color: colors.$color-white;
  padding-top: 40px !important;
  border-radius: 3px;
}

.MuiTableContainer-selfContainer {
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: colors.$color-white;
  padding-top: 25px !important;
  border-radius: 3px !important;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
  padding: 0 25px;
}

.MuiPaper-root {
  background-color: #f8f8fb !important;
  width: 100%;
  margin-top: -20px !important;
}

.MuiTableCell-root {
  padding: 16px 10px !important;
  font-family: fonts.$font-regular !important;
  font-size: 14px;
  padding-right: 16px;
}

.MuiTableCell-head {
  color: #000000 !important;
}

.MuiTableBody-root * {
  color: #0a2346;
}

.MuiPaginationItem-rounded {
  border-radius: 0 !important;
}

.MuiPaginationItem-root {
  margin: 0 !important;
  font-family: fonts.$font-regular !important;
}

.MuiPaginationItem-page.Mui-disabled {
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.table-footer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 3rem 0;

  @media screen and (max-width: 768px) {
    justify-content: center;

    .table-configuration {
      padding-right: 0;
    }

    .table-configuration > nav {
      transform: scale(1.5);
    }
  }
}

.table-total-data-lis-wrapper {
  margin: 0 auto;
}

.table-total-data-list {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 17px;
  margin-right: 5rem;
}

.table-row:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.table-configuration {
  display: flex;
  align-items: center;
  padding-right: 16px;
}

.MuiPaginationItem-page.Mui-selected {
  color: #ffffff !important;
  background-color: colors.$color-primary !important;
}

.MuiPaginationItem-root {
  min-width: 25px !important;
  height: 25px !important;
}

.MuiPagination-ul li:first-child button {
  border-radius: 3px 0px 0px 3px !important;
}

.MuiPagination-ul li:last-child button {
  border-radius: 0px 3px 3px 0px !important;
}

.table-head-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-icons {
  display: flex;
  align-items: center;
}

.order-icons div {
  width: 7px;
  height: 11px;
}

.arrowDown {
  background-image: url("../../assets/img/arrowDown.svg");
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.arrowUp {
  background-image: url("../../assets/img/arrowUp.svg");
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.arrowDown-active {
  transform: rotate(180deg);
  background-image: url("../../assets/img/arrowSelect.svg");

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.arrowUp-active {
  background-image: url("../../assets/img/arrowSelect.svg");

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.icon-wrapper {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  height: 20px !important;
  width: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon {
  pointer-events: none;
  height: 14px;
}

.icon-header {
  pointer-events: none;
}

input[type="checkbox"].checkbox + label:before {
  top: -0.7rem;
}

input[type="checkbox"].checkbox + label:after {
  top: -0.85rem;
}

.modal-options {
  display: flex;
  position: absolute;
  z-index: 99999;
  width: max-content;
  height: auto;
  overflow: auto;
  background: #0a2346;
  background-color: colors.$color-white;
  margin-top: 0.5rem;
  right: 4vw;
  // left: -11.5rem;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
}

.modal-options-list {
  list-style: none;
  padding: 15px;
  margin: 0;
}

.modal-options-list-item {
  cursor: pointer;
  margin: 5px 0;
  padding: 8px;
  display: flex;
  color: #0a2346;
}

.modal-options-list-item:hover {
  background-color: #f3f3f3;
}

.modal-options-list-item-icon {
  display: inline-block;
  pointer-events: none;
  margin-top: 1.5px !important;
}

.modal-options-list-item-label {
  pointer-events: none;
}

.modal-options-list-header {
  list-style: none;
  padding: 15px;
  margin: 0;
}

.modal-options-header {
  display: flex;
  position: absolute;
  z-index: 9999;
  width: max-content;
  height: auto;
  overflow: auto;
  background-color: colors.$color-white;
  top: 2rem;
  right: 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.1s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.options-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  cursor: pointer;
}

.options-wrapper {
  display: flex;
  align-self: center;
}

.head-wrapper {
  display: flex;
  justify-content: flex-end;
}

.withOutTitle {
  margin-top: -55px !important;
}

.options-header-wrapper {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.status-label {
  text-align: center;
  padding: 4px;
  border-radius: 5px;
  width: 140px;
  margin: 0 !important;
}

.status-label span {
  font-size: 12px !important;
  line-height: 17px !important;
  color: colors.$color-white !important;
}

.status-label-gerada {
  background: #8ac64b;
}

.status-label-rejeitada {
  background: colors.$color-danger;
}

.status-label-cancelada {
  background: #6c6c6c;
}

.status-label-cancelamento-em-processo {
  background: #4b4b4b;
}

.status-label-entrada {
  background: #8ac64b;
}

.status-label-saida {
  background: colors.$color-danger;
}

.status-label-aguardando {
  background: #eb5757;
}

.status-label-andamento {
  background: #1d1d1d;
}

.status-label-finalizado {
  background: #bdbdbd;
}

.status-label-ativa {
  background: #27ae60;
}

.status-label-nao-distribuido {
  background: #1b2473;
}

.status-label-nao-localizado {
  background: #858585;
}

.status-label-erro-integracao {
  background: #760000;
}

.status-label-default {
  background: #812990;
}

.status-label-inativo {
  background-color: colors.$color-text-secundary;
}
.status-label-em-andamento {
  background: #4b4b4b;
}

.status-label-pendente {
  background: #f15879;
}

.status-label-concluido {
  background: #27ae60;
}

.status-label-concluido-erro {
  background: #f7921e;
}

.status-label-erro {
  background: #bb0000;
}

.status-label-inativo-previsao {
  background: #c8c8c8;
  &.status-label span {
    color: #4b4b4b !important;
  }
}

.status-label-aguardando-previsao {
  background: #6c6c6c;
}

.status-label-finalizada {
  background: #8ac64b;
}

.moreoptionsdisplay-wrapper {
  position: relative;
}

.custom-table-title {
  margin: 0 !important;
  margin-bottom: 20px !important;
  color: #053b59;
  font-size: 20px;
}

.custom-filter-title {
  margin: 0 !important;
  margin-bottom: 10px !important;
  color: colors.$color-primary;
  font-size: 24px;
}

.filter-icon {
  margin-top: 10px !important;
}

.icon-menu {
  margin-top: 0.8px !important;
}

.cell-currency {
  text-align: right !important;
  width: max-content;
}

.table-head-item-title-left {
  text-align: left !important;
}

.table-head-item-title-center {
  text-align: center !important;
  width: 100%;
}

.status-label-ceu span {
  font-family: "Arial";
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  color: #4b4b4b;
  font-weight: 400 !important;
  text-transform: uppercase !important;
}

.status-label-ceu-solicitacao-ajustes span {
  color: #fcaf17 !important;
  font-family: "Arial";
  font-weight: 500 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  text-transform: uppercase !important;
}

.status-label-ceu-aprovado span {
  color: #00a991 !important;
  font-family: "Arial";
  font-weight: 500 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  text-transform: uppercase !important;
}

.status-label-ceu-contratado span {
  color: #227eb1 !important;
  font-family: "Arial";
  font-weight: 500 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  text-transform: uppercase !important;
}

.status-label-ceu-reprovado span {
  color: #eb5757 !important;
  font-weight: 500 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  font-family: "Arial";
  text-transform: uppercase !important;
}

.status-label-ceu-em-analise span {
  color: #59e9d4 !important;
  font-weight: 500 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  font-family: "Arial";
  text-transform: uppercase !important;
}

.status-label-ceu-default span {
  color: #4b4b4b !important;
  font-weight: 500 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  font-family: "Arial";
  text-transform: uppercase !important;
}

.text_ceu {
  font-family: fonts.$font-regular !important;
  font-weight: 500 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 16px;
  text-transform: uppercase !important;
}

.icon-ceu-search {
  background-position: center;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.icon-ceu-trash {
  background-position: center;
  background-repeat: no-repeat;
  width: 17px;
  height: 17px;
}

.config-display {
  display: flex;
  align-items: center;
}

.filter-container {
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-display-only {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-filter-list {
  cursor: pointer;
  margin-left: 20px;
  color: #ef4066;
}

.container-export-list {
  margin-left: 5px;
  cursor: pointer;
  color: #ef4066;
}

.menu-export {
  position: absolute !important;
  width: 250px;
  top: 50px !important;
}

.menu-list-export {
  div {
    display: flex;
    align-items: center;

    p {
      font-size: 13px;
      color: #0a2346;
    }

    svg {
      color: #0a2346;
    }
  }
}

.options-wrapper.fc-analise {
  justify-content: center !important;
}

.options-wrapper.align-column-left {
  justify-content: flex-start;
}

.status-conciliacao {
  border-radius: 5px;
  font-family: fonts.$font-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  height: 18px;
  justify-content: center;
  text-transform: uppercase;
  min-width: 80px;
  color: #ffffff !important;
}

.pago {
  background: #f15879;
}

.em-aberto {
  background: #bb0000;
}

.filter-indicator-without-more-icon::before {
  right: 0 !important;
}

.header-collapse-open {
  background-color: colors.$color-secondary;

  * {
    color: colors.$color-white !important;
  }
}

td.MuiTableCell-root.table-cell-empty {
  padding: 0 !important;
}

.div-responsive-collapse {
  border-bottom: solid 1px colors.$color-secondary;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-top: 10px;

  .collapse-row-value {
    text-align: right;
  }
}

.multi-label {
  display: flex;
  gap: 5px;

  .status-label {
    width: fit-content;
    padding: 2px 5px;
    display: flex;
  }

  @media screen and (max-width: 1100px) {
    display: block;

    .status-label {
      margin-top: 2px !important;
    }
  }
}
