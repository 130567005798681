@use "../../assets/styles/colors";

.container-relatorio {
  @media (max-width: 1100px) {
    padding-right: 0 !important;
  }
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiAccordionDetails-root {
  background-color: white !important;
}

.inner-cell-table-fluxo {
  height: auto;
  align-self: center;
  word-break: break-all;
  padding: 12px 1px !important;
  color: #4b4b4b;
  font-size: 13px;

  @media (min-width: 1100px) {
    min-width: 75px;
  }

  @media (max-width: 1099px) {
    word-break: break-word;
  }
}

.cell-table {
  height: auto;
  width: 75px;
  word-break: break-all;
  padding: 20px 1px !important;
  font-size: 13px;
  text-align: right;
  align-self: center;
  color: #ef4066;

  &.keep-number {
    word-break: keep-all;
    white-space: nowrap;

    &.cell-info {
      width: 75;
    }

    &.cell-table {
      min-width: 75px;
    }
  }
}

.name-column {
  width: 75px;
  padding: 10px 1px !important;
  font-weight: bold;
  direction: rtl;
  color: #4b4b4b;

  &.total-column {
    direction: ltr;
    text-align: right;
    width: 120px;
    margin-top: auto;
    margin-bottom: auto;

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.center-end {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.name-column-categoria-dre {
  width: 75px;
  padding: 10px 35px !important;
  text-align: left;
  font-weight: bold;
  color: #4b4b4b;
  width: calc(300px + 1em + 15px);
  min-width: calc(300px + 1em + 15px);
}

.table-details {
  background-color: white !important;
  max-height: auto !important;
  width: 99% !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0px !important;
  margin: 0px !important;
  display: block;
}

.accordion-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #ef4066;
  line-height: 19px;
  padding: 0 0 6px 15px !important;
}

.font-title {
  margin-left: 6px;
  padding: 12px 0 !important;
  height: auto;
}

.inner-font-title {
  margin-right: 0 !important;
  padding: 0 1.5rem 0 8px !important;
  align-self: center;
  height: auto;
}

.font-empty-title {
  color: #6c6c6c;
  font-size: 14px;
  font-weight: 600;
}

.font-empty-subtitle {
  color: colors.$color-secondary;
  font-size: 16px;
  font-weight: 600;
}

.empty-dre {
  background-color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 30px;
}

.dre-filter {
  position: relative;
  top: 10px;
}

.container-mobile-dados {
  margin-top: 20px !important;
}

.inner-font-title.total-value {
  font-weight: 700;
  text-align: right;
}

.inner-font-title.total-title {
  font-weight: 700;
  color: #4b4b4b;
}

.btn-primary.w-full.dre {
  @media (max-width: 1099px) {
    margin: 0;
  }

  @media (min-width: 1100px) {
    width: calc(100% - 12px);
  }
}

.text-center {
  text-align: center;
}

.accordion-dre {
  .right {
    text-align: right;
  }

  &.MuiAccordionDetails-root {
    margin-left: 0px;
    margin-right: 0px;
  }

  .container-total-categoria {
    margin-top: 20px;
  }

  .inner-font-title {
    margin-right: 0 !important;
    padding: 0 1.5rem 0 8px !important;
    align-self: center;
    height: auto;
    font-weight: 400;
    color: #ef4066;

    .right {
      margin-right: 0 !important;
      padding-right: 0 !important;
    }
  }

  .inner-font-title {
    padding-left: 0 !important;
  }

  .MuiGrid-grid-xs-4 {
    flex-grow: 0 !important;
    max-width: 33.333333% !important;
    flex-basis: 33.333333% !important;
  }
}

.container-dre {
  &.MuiGrid-container {
    overflow-x: scroll;
  }

  .MuiGrid-root.table-details.container-row-dre.MuiGrid-item {
    margin-left: 5px !important;
  }

  .Mui-expanded .container-row-dre {
    margin-left: -10px;
  }

  .font-title-total-mobile {
    font-weight: 400;
    color: #ef4066;
  }

  .container-header-dre,
  .container-row-dre {
    display: flex;
  }

  .name-categoria {
    width: 300px;
    min-width: 300px;

    &.title-categoria {
      width: calc(300px + 1em + 15px);
      min-width: calc(300px + 1em + 15px);
    }

    &.header {
      padding-left: 35px;
    }
  }

  .name-tipo-categoria {
    width: 150px;
    min-width: 150px;
    text-align: center;
  }

  .MuiPaper-root.MuiAccordion-root {
    width: fit-content;
    min-width: 100%;
  }
}

.relatorio-dre {
  &.table-no-header {
    @media (min-width: 1100px) {
      width: calc(97vw - 220px);
    }
  }
}

.mt-30 {
  margin-top: 30px !important;
}

.container-checkbox-all-categories {
  label {
    width: fit-content !important;
    max-width: 100%;
    padding-right: 25px !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
}

.accordion-dre.accordion-child-container {
  background: white;
  display: block;

  .accordion-child {
    width: 100%;
    background: #f8f8fb;
    box-shadow: none;

    .MuiAccordionSummary-content {
      border: 1px solid colors.$color-secondary;
      margin-bottom: 22px;
    }
  }
}
