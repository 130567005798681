.userInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.userInputsContainer {
  flex-direction: column;
  width: 100%;
  margin: 22px 0;
}

.inputsContainer {
  margin-top: 22px;
}

.picInputContainer {
  margin-top: 22px;
}

.userPicContainer {
  margin: 0 40px 0 80px;
}

.userMock {
  margin: 0 auto;
}

.userPicContainerGrid {
  margin-top: -50px;
  margin-left: 20px;
}

@media (max-width: 960px) {
  .userPicContainerGrid {
    margin-top: 0px;
  }
}

.gridLineOne {
  display: grid;
  gap: 22px 10px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 22px;
}

.gridLineTwo {
  display: grid;
  gap: 22px 10px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 22px;
}

.gridTwoFr {
  display: grid;
  gap: 22px 10px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 22px;
}

.checkboxInputContainer {
  margin-top: 23px;
  margin-bottom: 22px;
}

.checkboxSelectContainer {
  margin-bottom: 22px;
}

.descriptionInputContainer {
  margin-bottom: 32px;
}
