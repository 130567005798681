.container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.form-edit-user {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "name name name email email email"
    "login login login login login login"
    "company company company company company company"
    "profile profile profile profile profile profile"
    "checkbox checkbox checkbox checkbox checkbox checkbox"
    "hint submit submit submit submit submit" !important;
}

.name-input {
  grid-area: name;
}

.profile-input {
  grid-area: profile;
}

.email-input {
  grid-area: email;
}

.cpf-input {
  grid-area: login;
}

.password-input {
  grid-area: password;
}

.confirm-password-input {
  grid-area: confirm-password;
}

.company-input {
  grid-area: company;
}

.hint-form {
  grid-area: hint;
}

.submit-form {
  grid-area: submit;
}

.checkbox-input{
  grid-area: checkbox;
}

.form-submit {
  float: right;
  margin: 20px 0 0 0;
}

.password-icon {
  z-index: 2;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
}

.editar-usuario-permissoes-title {
  margin: 0;
  padding-top: 30px;
  z-index: 999;
}

.editar-usuario-button-area {
  padding: 24px 0;
}

.container-checkbox-input-ativo label{
    width: fit-content !important;
    max-width: 100%;
    padding-left: 25px !important;
    margin-left: 0 !important;
}

.container-checkbox-input-ativo label::before{
  left: 0 !important;
  right: auto !important;
}

.container-checkbox-input-ativo label::after{
  left: 3px !important;
  right: auto !important;
}

.company-input .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
  max-height: 160px;
  overflow-y: scroll;
  overflow-x: hidden;
}