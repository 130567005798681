.label {
  position: relative;
  padding-bottom: 0.275rem;
  color: #EF4066;
  font-size: 14px;
  line-height: 21.5px;
  display: flex;
}

.input_text {
  width: 100%;
  outline: none;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #6c6c6c;
  height: 40px;
  background-color: white;
}

.input_button {
  max-width: 150px;
  background-color: #f8f8fb;
  color: #EF4066;
  display: flex;
  justify-content: center;
  align-content: center;
  color: #EF4066;
  font-size: 14px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.input_file {
  width: 0.1px;
  height: 0px;
}

.label {
  margin: 6px 0 0 0;
}

.text {
  margin-top: 9px;
  margin-left: 10px;
  overflow: hidden;
  font-style: italic;
  white-space: nowrap;
}

.suffixLabel {
  margin: 0 16px;
  display: flex;
}
.suffixIconShare {
  margin-top: 4px;
}
.suffixIconShare:hover {
  cursor: pointer;
}
.suffixIconInfo {
  color: #812990 !important;
  font-size: 16px !important;
  margin: 3px 16px 0 0 !important;
}


@media (max-width: 1100px){
  .containerFileName{
    border: 1px solid #c8c8c8;
    padding-bottom: 9px;
    margin-bottom: 15px !important;
  }
  .input_text{
    height: auto;
    border: none;
  }
}