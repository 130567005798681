.redefinir-submit-button {
  display: flex;
  justify-content: flex-end;
}

.password-message {
  color: #812990;
  font-size: 12px;
  border: 1px solid #812990;
  width: 100%;
  outline: none;
  padding: 0.6rem;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
