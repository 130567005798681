@font-face {
  font-family: "Lato Regular";
  src: url(Lato-Regular.ttf);
}
@font-face {
  font-family: "Lato Thin";
  src: url(Lato-Thin.ttf);
}
@font-face {
  font-family: "Lato Bold";
  src: url(Lato-Bold.ttf);
}
@font-face {
  font-family: "Lato Black";
  src: url(Lato-Black.ttf);
}
@font-face {
  font-family: "Lato Italic";
  src: url(Lato-Italic.ttf);
}
@font-face {
  font-family: "Raleway";
  src: url(Raleway-Regular.ttf);
}
$font-regular: "Lato Regular";
$font-thin: "Lato Thin";
$font-bold: "Lato Bold";
$font-italic: "Lato Italic";
$font-black: "Lato Black";
$font-raleway: "Raleway";
