.marginLeftAuto {
  margin-left: auto;
}

.warningTitle {
  margin-top: 25px;
}

.containerEditableValue {
  text-align: center;
}

.containerInputEdit {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1100px) {
    max-width: 125px;
  }
}
