.input-nativo {
  .validation-error {
    color: #b00;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 4px;
  }
}
