.user-register-container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
  display: block !important;
}

.form-create-user {
  margin: 0 auto !important;
  max-width: 770px !important;
  width: 100% !important;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "name name email email login login"
    "company company company company company company"
    "profile profile profile profile profile profile" 
    "checkbox checkbox checkbox checkbox checkbox checkbox"!important;
}

.name-input {
  grid-area: name;
}

.profile-input {
  grid-area: profile;
}

.email-input {
  grid-area: email;
}

.cpf-input {
  grid-area: login;
  height: 62px;
}

.password-input {
  grid-area: password;
  height: 62px;
}

.confirm-password-input {
  grid-area: confirm-password;
  height: 62px;
}

.company-input {
  grid-area: company;
}

.submit {
  float: right;
  margin: 0;
  margin-top: 20px;
}

.space-vertical {
  margin-top: 10px;
}

.checkbox-input{
  grid-area: checkbox;
}

.password-icon {
  z-index: 2;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  
  position: relative;
  left: calc(85% - 0.2rem);
  top: -1.9rem;
}

.container-checkbox-input-ativo label{
  width: fit-content !important;
  max-width: 100%;
  padding-left: 25px !important;
  margin-left: 0 !important;
}

.container-checkbox-input-ativo label::before{
left: 0 !important;
right: auto !important;
}

.container-checkbox-input-ativo label::after{
left: 3px !important;
right: auto !important;
}

@media (max-width: 760px) {
  .form-create-user {
    grid-template-areas:
      "name name name name name name"
      "email email email email email email"
      "login login login login login login"
      "company company company company company company"
      "profile profile profile profile profile profile" 
      "checkbox checkbox checkbox checkbox checkbox checkbox"!important;
  }
}