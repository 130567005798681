@use '../../assets/styles/colors';

.card-table-success {
  border-radius: 5px;
  background-color: colors.$color-success;
  font-weight: 600px;
  color: white !important;
  padding: 4px 6px;
  width: 100%;
  margin-top: 6px;
  text-align: center;
  font-size: 12px;
}

.table-no-header {
}


.filter-container {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.card-table-danger {
  border-radius: 5px;
  background-color: colors.$color-danger;
  font-weight: 600px;
  color: white !important;
  padding: 4px 6px;
  margin-top: 6px;
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.card-table-warning {
  border-radius: 5px;
  background-color: colors.$color-warning;
  font-weight: 600px;
  color: white !important;
  padding: 4px 6px;
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.card-table-free {
  border-radius: 5px;
  border: 1px solid colors.$color-secondary;
  background-color: transparent;
  font-weight: 600px;
  color: colors.$color-secondary !important;
  padding: 4px 6px;
  font-size: 12px;
  width: 100%;
  text-align: center;
}

.card-table-disabled {
  border-radius: 5px;
  background-color: colors.$color-text-secundary;
  font-weight: 600px;
  color: white !important;
  padding: 4px 6px;
  margin-top: 6px;
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.button-grid-item-container {
  align-self: flex-end;
  max-width: calc(33.33% / 2) !important;
}

.clean-filters-button {
  margin: 0 !important;
  border-radius: 3px !important;
  padding: 0.5rem 0 !important;
  width: 100%;
  height: 38px;
}

.table-relatorio-assinantes .MuiTableContainer-root{
  overflow-x: scroll !important;
}

.filter-container.relatorio-assinantes .MuiGrid-root.form-table.MuiGrid-container{
  justify-content: center !important;
}