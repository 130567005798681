@use '../../assets/styles/colors';

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.inputLabel {
  position: relative;
  margin-bottom: 0.5rem;
  color: colors.$color-text-primary;
  font-size: 14px;
  line-height: 25px;
}

.input {
  background-color: transparent;
  position: relative;
  z-index: 1;
  background-color: transparent;
  cursor: pointer;
}

.requiredItem {
  margin: 0 0.1rem;
}

.input {
  width: 100%;
  outline: none;
  padding: 0.6rem !important;
  border: 1px solid #c8c8c8 !important;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  input {
    text-align: left;
  }

  input::placeholder {
    font-size: 14px;
    color: #6c6c6c;
    font-style: italic;
  }
}
.inputContainer {
  position: relative;
  display: flex;
}

.paperRoot {
  position: relative;
  top: 40px;
}
