@use "../../../assets/fonts/fonts";

.large-input {
  border: solid 1px #e8ebf1;
  margin-top: 8px;
  height: 70px;
  font-family: fonts.$font-regular;
  font-size: 14px;
  padding: 8px;
  max-width: 97.5%;
}

.large-input:focus {
  outline: none;
}
