.marginLeftAuto{
  margin-left: auto;
}

.chat.integracao-questor:before {
  right: 0px;
}

.container-lista-integracoes .MuiTableContainer-root{
  overflow-x: auto !important;
}