:root {
  --darkBlue: #EF4066;
  --lightBackground: #f8f8fb;
  --lightBorder: #e8ebf1;
  --darkGrey: #4b4b4b;
  --orange: #812990;
}
.MuiAccordion-root:before {
  background-color: var(--lightBackground) !important;
}

.MuiAccordionDetails-root {
  margin-left: -16.3px;
  margin-right: -16.3px;
}

.body {
  margin-top: 32px;
  gap: 16px;
}

.space-vertical {
  height: 12px;
}

.space-large-vertical {
  margin-bottom: 45px;
}

.space-vertical-xl {
  height: 40px;
}
.label {
  color: var(--darkBlue);
  font-size: 14px;
  margin-left: 0 !important;
}

.font-title {
  color: var(--darkBlue);
  font-weight: 600;
}

.subtitle-background {
  background-color: var(--lightBackground);
  color: var(--darkGrey);
  padding: 6px;
  margin-left: 5px !important;
  width: calc(100% - 10px) !important;
  line-height: 15px;
  border: 1px solid #e8ebf1;
}

.text-pleft {
  padding-left: 6px;
}
.text-mleft {
  margin-left: 6px;
}

.font-orange {
  color: var(--orange);
}

.font-darkBlue {
  color: var(--darkBlue);
}

.background-light {
  background-color: var(--lightBackground);
}

.search-wrapper {
  display: inline-flex;
  background: #f8f8fb;
  border-radius: 3px;
  padding: 0.6rem;

  position: relative;
  left: 80%;
}

.accordion-arrow {
  margin-top: 6px;
}

.font-small {
  font-size: 2px;
}

.card-entrada {
  border-radius: 5px;
  background-color: #8ac64b;
  font-weight: 600px;
  color: white !important;
  padding: 4px 6px;
  width: 78px;
  text-align: center;
}

.card-saida {
  border-radius: 5px;
  background-color: #bb0000;
  font-weight: 600px;
  color: white !important;
  padding: 4px 6px;
  width: 78px;
  text-align: center;
}
