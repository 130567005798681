.fatura-container-default {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.fatura-container-default-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
