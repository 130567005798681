.icon-button {
  padding: 7px 0 7px 9px;
}

.text-button {
  padding: 7px 8px !important;
  margin-left: 0;
}

.text-label {
  margin-left: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.documento-contabil-submit {
  margin-bottom: 50px !important;
  z-index: 2;
}
.documento-contabil-container {
  border-radius: 3px;
  background-color: white;
  padding: 30px 0px;
}
