@use "../../assets/styles/colors";

:root {
  --header-sidebar-width: 220px;
  --header-sidebar-bg-color: #ffffff;
}

.header-sidebar {
  z-index: 2;
  position: fixed;
  top: 0;
  padding-top: 84px;
  width: var(--header-sidebar-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--header-sidebar-bg-color);
  border-right: 1px solid #e7e8ec;
  height: 100%;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .header-sidebar {
    padding-top: 60px;
    width: 100%;
    height: 100%;
    z-index: 6;
  }
}

.header-logo-mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 60px;
  position: relative;
  top: -30px;
  left: 20px;

  img {
    width: 130px;
  }
}

.header-close-wrapper {
  padding: 10px;
  color: colors.$color-primary !important;
  padding-right: 50px !important;
}

.header-close-wrapper:hover {
  cursor: pointer;
}
