.filter-button-area {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  button {
    margin: 0 !important;
    margin-right: 8px !important;
    margin-top: 30px !important;
    width: 150px !important;
  }
}
