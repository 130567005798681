.cadastrar-conta {
  .account-register-container {
    height: auto !important;
    padding: 2.4rem 0 3.4rem !important;
  }

  .cadastro-contas-form {
    padding: 0 1.2rem !important;
    margin: 0 auto;
    max-width: 770px;
    width: 100%;

    .container-form {
      max-width: 770px;
      width: 100%;
      .container-campos-obrigatorios {
        padding-right: 0;
        & > * {
          padding-right: 0;
        }
      }
      & > * {
        height: fit-content;
      }
    }
    &.mostrar-widget {
      width: 100%;
      max-width: calc(100% - 220px);
      .container-form {
        max-width: 100%;
        display: block;
      }
    }
  }
  .MuiGrid-grid-xs-4 {
    flex-grow: 0;
    max-width: 33.333333% !important;
    flex-basis: 33.333333% !important;
  }
}
