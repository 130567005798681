@use '../../../assets/fonts/fonts';
@use '../../../assets/styles/colors';

.p-horizontal {
  width: 65%;
  margin: auto;
}

.space-vertical {
  margin-top: 19px;
}

.space-vertical-small {
  margin-bottom: 6px;
}

.error {
  border: 1px solid firebrick !important;
}

.container-input-switch {
  display: flex;
  align-items: center;
  color: #EF4066;
}

.text-background {
  margin-top: 7px;
  background-color: #f8f8fb;
  border: 1px solid #c8c8c8;
  color: colors.$color-text-primary;
  padding: 6px;
  border-radius: 3px;
  height: 38px;

  display: flex;
  align-items: center;
}

.text-border {
  margin-top: 0.4rem;
  background-color: colors.$color-white;
  border: 1px solid #c8c8c8;
  color: colors.$color-text-primary;
  padding: 6px;
  border-radius: 3px;
  width: 95%;
  font-size: 14px;
  height: 38px;
  outline: 0 !important;
  cursor: pointer;
}

.label {
  color: colors.$color-primary;
  line-height: 21.5px;
}

.input-number {
  width: 100%;
  border: none;
  border-radius: 3px;
}

.input-number:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: none;
}

.perfilTributario-title {
  color: colors.$color-primary;
  padding-bottom: 27px;
}

.perfilTributario-titleSubtitle {
  color: colors.$color-primary;
  padding-bottom: 4px;
}

.perfilTributario-subTitle {
  font-size: 14px;
}

.zero-margin {
  margin: 0;
}

.font-hint {
  color: colors.$color-text-secundary;
  font-size: 14px;
}

.nf-hint {
  position: relative;
  top: -20px;
}

.photo-hint {
  margin-left: 2px;
  color: colors.$color-text-secundary;
  font-size: 12px;
}

.required-text {
  margin-top: 20px !important;
}
