.gestao-turno-container {
  width: 75%;
  margin: auto;
  display: flex;
  place-content: center;
  padding: 30px;
}

.btn-saveGestao {
  color: #ffffff;
  background-color: #222222;
  border-radius: 3px;
  width: 120px;
  height: 32px;
}

.textTurnos {
  font-size: 14px;
  font-weight: 300;
}

.textLegenda {
  font-size: 14px;
  font-weight: normal;
}

.titleGestaoTurno {
  color: #4b4b4b;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
