@use "../../assets/styles/colors";

.modal-title-area {
  background-color: #ffff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  overflow-x: hidden;
}

.modal-title {
  font-size: 16px;
  color: colors.$color-primary;
  text-shadow: 0.8px 0 0px colors.$color-primary;
}
