.cadastrar-documento-legal-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "titulo titulo categoria"
    "empresa empresa empresa"
    "arquivo arquivo arquivo";
}

.cadastrar-documento-legal-titulo-input {
  grid-area: titulo;
}

.cadastrar-documento-legal-categoria-input {
  grid-area: categoria;
}

.cadastrar-documento-legal-empresa-input {
  grid-area: empresa;
}

.cadastrar-documento-legal-file-input {
  grid-area: arquivo;
}

.cadastrar-documento-legal-view-file-btn {
  padding: 0.3em 2em !important;
}

.cadastrar-documento-legal-remove-file-btn {
  padding: 0.3em 0.3em !important;
  margin-left: 10px;
}

.cadastrar-documento-legal-remove-file-btn .button-icone,
.cadastrar-documento-legal-remove-file-btn .button-icone .icon {
  margin: 0 !important;
}

.button-icone {
  position: relative;
  top: 2px;
}

.cadastrar-documento-legal-file-actions {
  display: flex;
  flex-direction: row;
  grid-area: arquivo;
}

.btn-align {
  width: 30%;
  float: right;
  margin: 20px 0;
}
