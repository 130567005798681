@use "../../assets/fonts/fonts";
@use "../../assets/styles/colors";

.modalInstabilidade {
  .botaoFecharModal {
    position: absolute;
    top: -30px;
    right: -30px;
    padding: 0 !important;
    margin: 0 !important;
    color: colors.$color-white;

    .MuiSvgIcon-root {
      font-size: 3rem;
    }
  }

  .containerModal {
    .containerNumeroVersao {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 45px;
      .containerNumeroItemVersao {
        font-family: fonts.$font-bold;

        &::before,
        &::after {
          position: absolute;
          content: "";
          transition: all 0.5s;
        }
        & {
          color: white;
          transform: translate(0%, -25%);
          vertical-align: middle;
          text-decoration: none;
          font-size: 4vw;
          transition: all 0.5s;
          background-color: #f15879;
          font-size: 86px;
          border: solid 2px #000;
          width: 200px;
          height: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &::before {
          bottom: -16px;
          height: 11px;
          width: 100%;
          left: 4px;
          transform: skewX(40deg);
          background-color: #f15879;
          border: solid 2px #000;
        }
        &::after {
          right: -14px;
          height: 100%;
          width: 9px;
          bottom: -9px;
          transform: skewY(50deg);
          background-color: #f15879;
          border: solid 2px #000;
        }
        &:active {
          margin-left: 10px;
          margin-top: 10px;
        }
        &:active::before {
          bottom: -5px;
          height: 5px;
          left: 3px;
        }
        &:active::after {
          right: -5px;
          width: 5px;
          bottom: -3px;
        }
      }
    }
    .containerTitle {
      color: colors.$color-primary;
      font-family: fonts.$font-bold;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }

    .containerSubtitle {
      color: colors.$color-dark-gray;
      text-align: left;
      font-family: fonts.$font-regular;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    }
    .containerBotao {
      text-align: center;
      margin-top: 20px;

      button {
        border-radius: 40px;
        background: colors.$color-primary;
        border: none;
        cursor: pointer;
        color: colors.$color-white;
        padding: 10px 45px;

        font-family: fonts.$font-regular;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialog-paper {
    overflow: visible;
    border-radius: 50px;

    width: 870px !important;
    max-width: 95% !important;
    padding: 20px !important;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .MuiDialogContent-root.modal-content {
      overflow: visible;
      border-radius: 50px;

      width: 870px !important;
      max-width: 100% !important;
      background-color: transparent !important;
    }
  }

  @media (max-width: 880px) {
    .containerModal {
      .containerNumeroVersao {
        .containerNumeroItemVersao {
          & {
            width: 180px;
          }
        }
      }

      .containerTitle {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    .containerModal {
      .containerNumeroVersao {
        gap: 20px;
        .containerNumeroItemVersao {
          & {
            width: 150px;
            height: 110px;
          }
        }
      }
      .containerTitle {
        font-size: 28px;
      }
      .containerSubtitle {
        font-size: 22px;
      }
    }

    .MuiDialog-paper {
      max-width: 90% !important;
      padding: 40px !important;
    }
  }

  @media (max-width: 628px) {
    .containerModal {
      .containerNumeroVersao {
        gap: 20px;
        .containerNumeroItemVersao {
          & {
            width: 120px;
            height: 90px;
          }
        }
      }
    }
  }

  @media (max-width: 628px) {
    .containerModal {
      .containerNumeroVersao {
        gap: 15px;
        .containerNumeroItemVersao {
          & {
            width: 70px;
            height: 60px;
            font-size: 40px;
          }
        }
      }
    }
  }
}
