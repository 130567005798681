.formContainer {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
}

.gridLayout {
  display: grid;
  gap: 22px 10px;
}

.formSectionOne {
  grid-template-areas:
    "nome nome"
    "valorTabela valorPlano"
    "periodo status";
  padding-bottom: 36px;
  border-bottom: 1px dashed rgba(5, 59, 89, 0.24);
  margin-bottom: 25px;
}

.planoPlafatorma {
  grid-area: planoPlataforma;
  color: #EF4066;
  font-size: 14px;
  padding-left: 4px;
}

.planoSubTitle {
  grid-area: planoSubtitle;
}

.planoDescricao {
  grid-area: planoDescricao;

  textarea {
    resize: none;
  }
}

.planNameInputContainer {
  grid-area: nome;
}

.tableValueInputContainer {
  grid-area: valorTabela;
}

.planValueInputContainer {
  grid-area: valorPlano;
}

.planTimeInputContainer {
  grid-area: periodo;
}

.planStatusInputContainer {
  grid-area: status;
}

.formSectionTwo {
  grid-template-areas:
    "servico quantidade"
    ". button";
}

.servicesInputContainer {
  grid-area: servico;
}

.quantityInputContainer {
  grid-area: quantidade;
}

.addButton {
  grid-area: button;
  display: flex;
  flex-direction: row-reverse;
}

.addButton > button {
  margin: 0 !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.sectionTitle {
  font-size: 16px;
  color: #812990;
  margin-bottom: 20px;
}
