.container-cadastrar-documento-contabil {
  .p-horizontal {
    width: 65%;
    margin: auto;
  }

  .zero-margin {
    margin: 0;
  }
}
