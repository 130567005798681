.category-register-container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
}

.pagina-cadastro-subcategoria {
  &.session-container {
    height: fit-content;
  }
}
