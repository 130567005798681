.content-transacao {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
}

.MuiDialog-root {
  background-color: transparent !important;
}

.lancamento-recorrente-titulo {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
