.p-horizontal {
  width: 65%;
  margin: auto;
}

.zero-margin {
  margin: 0;
}

.text {
  margin: 0 0 0 4px !important;
}
