@use "../../../../assets/styles/colors";

.card-escolhe-plano-root {
  display: flex;
}

.card-escolhe-plano {
  width: 260px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(121, 121, 121, 0.1);
  border-radius: 15px;
  padding-bottom: 24px;

  > hr {
    border: 1px solid colors.$color-secondary;
  }
}

.card-escolhe-plano-header {
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
}

.escolhe-plano-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: colors.$color-primary;
}

.escolhe-plano-description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: colors.$color-primary;
}

.card-escolhe-plano-titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.escolhe-plano-valorWrapper {
  margin-top: 26px;
  display: flex;
}

.escolhe-plano-valorText {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
  color: colors.$color-primary;
}

.escolhe-plano-buttonWrapper {
  display: flex;
  margin-top: 30px;

  button {
    background-color: colors.$color-secondary;
    border-radius: 10px;
    width: 230px;
    height: 30px;
    border: none;
    color: colors.$color-white;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
  }
  button:focus {
    outline: 0;
  }
}

.escolhe-plano-mes {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: colors.$color-primary;
  margin-top: 32px;
  margin-left: 4px;
}

.escolhe-plano-cifrao {
  color: colors.$color-primary;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  display: inline-flex;
  margin-top: 5px;
}

.escolhe-plano-funcionalidades {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 18px;

  > div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
  }
}

.escolhe-plano-funcionalidade {
  max-width: 180px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: colors.$color-primary;
}

.escolhe-plano-ver-todos { 
  cursor: pointer; 
  display: flex;
  flex-direction: column;
  align-items: center;

  div {  
    color: colors.$color-secondary;
    svg {  
      height: 20px;
      width: 20px;
    }
  }
}


