@use '../../assets/fonts/fonts';
@use '../../assets/styles/colors';

.wrapper {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.panel {
  position: relative;
  background-image: url('../../assets/img/fatura-boleto-frame-header-2.png');
  height: 140px;
}

.logoWrapper {
  position: absolute;
  top: 78px;
  transform: translateY(-50%);
  left: 58px;
}

.infoWrapper {
  position: absolute;
  top: 78px;
  transform: translateY(-50%);
  right: 58px;
}

.infoWrapper span {
  display: block;
  font-size: 12px;
  line-height: 14.4px;
  color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 40px 45px;
  align-items: center;
  text-align: center;
}

.header h1 {
  margin: 0;
  color: colors.$color-secondary;;
  font-family: fonts.$font-thin;
  font-size: 52px;
  line-height: 62.4px;
  text-align: left;
}

.headerInfo {
  display: flex;
  justify-content: center;
}

.headerInfo span {
  display: block;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  margin-bottom: 6px;
  color: #EF4066;
}

.headerInfoLabels {  
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
}

.headerInfoValues {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 22px;
  text-align: left;
}

.headerInfoStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 45px;
}

.headerInfoStatus span.headerInfoStatusDue {
  color: #bb0000;
  font-size: 12px;
  margin-top: 5px;
}

.headerInfoStatus span {
  color: #8ac64b;
  font-size: 12px;
  margin-top: 5px;
}

.invoiceDescription {
  background-color: #f8f8fb;
  padding: 27px 45px;
  border-bottom: 1px dotted #EF4066;
}

.invoiceDescriptionFields {
  display: flex;
  flex-direction: column;
}

.companyInfo {
  display: grid;
  gap: 10px 0;  
}

.infoTitle {
  color: #005E78;
  font-size: 12px;
}

.infoValue {
  font-size: 12px;
  color: colors.$color-secondary;;
}

.serviceInfo {
  margin-top: 50px;
}

.serviceInfoTitle {
  color: #EF4066;
  font-size: 14px;
}

.serviceInfoTable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  color: #EF4066;
  font-size: 14px;
}

.serviceInfoTable th {
  font-weight: normal;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.serviceInfoTable > thead > tr > th {
  padding: 9px 16px;
}

.serviceInfoTable > tbody > tr > td {
  padding: 16px;
}

.serviceInfo tr,
.serviceInfo th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.serviceInfo tr:last-of-type {
  border: 0;
  font-weight: bold;
}

.paymentInfo {  
  display: flex;
  flex-direction: column;
}

.paymentTitle {
  text-align: center;
  font-size: 24px;
  color: #005e78;
  margin: 0 !important;
  padding: 40px 0;
  line-height: 28.8px;
}

.creditCardChoice {
  background: #f8f8fb;
  border: 1px solid #edf1f7;
  border-radius: 8px;
  padding: 12px 40px 12px 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flexCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.creditCard .checkboxLabel,
.debitCard .checkboxLabel {
  color: #1d1d1d;
  margin-left: 18px;
  font-size: 20px;
}

.paymentForm {
  display: grid;
  grid-gap: 1rem;
  margin: 30px 16px 16px 16px;
  
}

.flexColumn {
  flex-direction: column;
}

.cardNumber {
  grid-area: number;
}

.cardName {
  grid-area: name;
}

.cardValidity {
  grid-area: validity;
}

.cardSecurityCode {
  grid-area: securityCode;
}

.buttonArea {
  margin-right: 16px;
  margin-top: 15px;
  display: flex;
  flex-direction: row-reverse;
}

.buttonArea button {
  margin: 0;
}

.payment {
  border-bottom: 2px dotted #EF4066;
  padding-bottom: 80px;
  position: relative;
}

.paymentWithoutBillet {
  padding-bottom: 80px;
  position: relative;
}

.scissorsIcon {
  position: absolute;
  left: 0;
  top: 100%;
  transform: translateY(calc(-100% + 4px));
}

.bankSlip {
  margin: 0 auto;
  width: 100%;
  max-width: 1180px;
}

.bankSlipLine {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.bankSlipLine > h1 {
  color: colors.$color-secondary;;
  font-size: 16px;
}

.lineActions {
  display: flex;
  width: 80% !important;
  align-items: center;
  justify-content: center;
}

.lineActions button {
  margin: 0;
  margin-left: 10px;
  height: 32px;
}

.bankSlipInputContainer {
  flex-direction: column;
  margin-top: -4px;
}

.bankSlipInputContainer input {
  height: 32px;
  font-size: 16px;
  color: #EF4066;
}

.bankSlipInputContainer label {
  display: none;
}

.bankSlipContainer {
  margin: 45px auto;
  border: 1px solid #e8ebf1;
  padding: 10px;
  max-width: 1180px !important;
  width: 100%;
  transform: translateX(-10px);
}

.bankSlipActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 32px 0;
  background: #f8f8fb;
  border-top: 1px dashed #EF4066;
}

.bankSlipActions button {
  font-size: 14px;
  font-weight: 500;
}

.menuWrapper {
  position: relative;
  margin-bottom: 15px;
  left: 100%;
  transform: translateX(-26px);
  cursor: pointer;
  z-index: 9999;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modalOptions {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
}

.modalOptionsHeader {
  position: absolute !important;
  left: -7.8% !important;
}

.buttonOutsideArea {
  margin: 0;
  margin-top: 30px;
  margin-right: 2px;
}

.paymentLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 9px 0;
  font-size: 14px;
}

.paymentLabel img {
  margin-right: 17px;
}

.paymentSuccess {
  background: #8ac64b;
}

.paymentAlert {
  background: #bb0000;
}

.boletoDescription {  
  width: 75%;
  margin: auto;
  color: #005E78;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
}

.companyName {  
  grid-column: auto /span 2;
}

.containerParcelar{
  margin-left: 16px !important;
}

@media (min-width: 626px) {
  .paymentForm { 
    grid-template-columns: repeat(2, 1fr); 
  } 
}

@media (max-width: 625px) {  
  .panel {  
    display: flex;
  }


.lineActions button {
  margin-left: 0;
}
  
  .infoWrapper {  
    width: 100%;
    position: absolute;
    left: 0;
    top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
  }
  .logoWrapper {
    width: 100%;
    position: absolute;
    left: 0;
    top: 38px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    img {  
      height: 60px !important;
    }
  }
  .paymentForm {  
    grid-template-columns: repeat(1, 1fr);
  }
  .buttonArea { 
    justify-content: center;
    margin-right: 0 !important;
  }
  .companyAdress {  
    grid-column: auto /span 2;
  }
  .companyDistrict {  
    grid-column:  auto /span 2;
  }
}