.table {
  border-collapse: collapse;
  width: 770px;
  font-size: 10px;
}

.td {
  font-size: 14px;
  font-weight: 800;
  border-right: 1px solid #000000;
  border-left: 1px solid #000000;
  padding: 0;
  text-align: center;
}

.tdOne {
  vertical-align: top;
  border: 1px solid #000;
  border-left: 0px none;
}

.tdTwo {
  width: 22%;
  vertical-align: top;
  border: 1px solid #000;
  border-right: 0px none;
}

.tdThree {
  vertical-align: top;
  border: 1px solid #000;
  border-left: 0px none;
}

.tdFour {
  width: 22%;
  vertical-align: top;
  border: 1px solid #000;
  border-right: 0px none;
}

.tdFive {
  vertical-align: top;
  border: 1px solid #000;
  border-left: 0px none;
}

.tdSix {
  vertical-align: top;
  border: 1px solid #000;
  border-left: 0px none;
}

.tdSeven {
  vertical-align: top;
  border: 1px solid #000;
  border-left: 0px none;
}

.tdEight {
  vertical-align: top;
  border: 1px solid #000;
  border-right: 0px none;
}

.tdNine {
  vertical-align: top;
  border-bottom: 1px solid #000;
}

.span {
  vertical-align: top;
  text-align: center;
  float: right;
  font-size: 10px;
  padding-right: 3px;
}

.barcodeWrapper {
  max-width: 400px;
  max-height: 69px;
}

.tdBottom {
  vertical-align: bottom;
  border: 1px solid #000;
  border-right: 0px none;
}

.lastRowRecibo {
  border-bottom: 1px dashed #6c6c6c;
}

.tdAgencia {
  vertical-align: top;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
}
