@use '../../../assets/styles/colors';

.cadastrar-contratuais-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.textChecked {
  color: colors.$color-secondary !important;
}

.cadastrar-contratuais-form h3 {
  margin-top: 0;
  color: colors.$color-secondary;
}

.cadastrar-contratuais-form .textSpan {
  font-size: 14px;
  font-weight: 400;
  color: colors.$color-primary;
}

.cadastrar-contratuais-form p {
  font-size: 14px;
  font-weight: 700;
  color: colors.$color-text-secundary;
}

.gridXs4Force {
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;

  @media (min-width: 900px) {
    max-width: 33.33%;
    flex-basis: 33.33%;
  }
}

.empregoWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-top: 10px;

  flex-direction: row;
}

.checkboxWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 32px;

  flex-direction: row;
}
