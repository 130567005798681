.marginLeftAuto{
  margin-left: auto;
}

.chat.integracao-questor:before {
  right: 0px;
}

.custom-table-title.title-outside-table{
  line-height: 29px;
  padding-bottom: 20px;
}

.header-bar-title-page.custom-table-title.title-outside-table{
  color: #EF4066;
}

.container-cadastra-integracoes .MuiTableContainer-root{
  overflow-x: auto !important;
}