.user-register-container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
}

.company-config-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "descricao descricao"
    "provisao ferias";
}

.company-config-form-descricao-input {
  grid-area: descricao;
}

.company-config-form-provisao-input {
  grid-area: provisao;
}

.company-config-form-ferias-input {
  grid-area: ferias;
}

.user-register-form-password-icon {
  z-index: 2;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;

  position: relative;
  left: calc(85% - 0.2rem);
  top: -1.9rem;
}

.user-register-form-submit-btn {
  display: flex;
  justify-content: flex-end;
}

.zero-margin {
  margin: 0;
}
