@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.suffixLabel {
  margin: 0;
  display: flex;

  span {
    color: colors.$color-secondary;
  }

  svg {
    margin-top: 0 !important;
  }
}
