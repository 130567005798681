.platform-config-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "nfe"
    "boleto"
    "transferencia";
}

.platform-config-nfe-select {
  grid-area: nfe;
}

.platform-config-boleto-select {
  grid-area: boleto;
}

.platform-config-transferencia-select {
  grid-area: transferencia;
}

.user-register-form-password-icon {
  z-index: 2;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;

  position: relative;
  left: calc(85% - 0.2rem);
  top: -1.9rem;
}

.configuracao-email-automatico.session-container{
  background-color: transparent !important;
  box-shadow: none !important;
  margin-bottom: 0;
  padding-bottom: 0 !important;
}

.configuracao-email-automatico .jodit-container:not(.jodit_inline) {
  border: '1px solid #c8c8c8 !important';
  border-radius: 3px;
}

.configuracao-email-automatico .jodit-workplace{
  font-size: 13px !important;
  background-color: #fff;
}

.modal-email-relatorio .jodit-workplace{
  font-size: 13px !important;
  background-color: #fff;
}