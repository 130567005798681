@use "../../assets/styles/colors";

.no-notifications {
  width: 100%;
  display: flex;
  margin-left: 40.5px;
}

.container-notification {  
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 8px; 
  background-color: rgba(0,64,113,0.06);
  border-radius: 12px;
  cursor: pointer;
}

@media (max-width: 1100px) {  
  .container-notification {
    position: relative;
    top: -20px;
    width: 90vw;
  }
}

.text-notification { 
  font-size: 12px;
  color: colors.$color-primary;
}

@media (max-width: 1100px) { 
  .text-notification { 
    padding-left: 5px;
  }
}

.avatar-icon-wrapper {  
  border-radius: 50%;
  display: inline-flex;
  place-content: center;
  width: 20px;
  height: 20px;
  div {  
    place-self: center;
    display: flex;
    svg {  
      place-self: center;
      padding-left: 10px;
    }
  }
}

.has-notifications-wrapper {
  background-color: colors.$color-secondary;
}

.no-notifications-wrapper {
  background-color: #ACBCC8 !important;
}

.notification-item {  
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 8px;   
}

.text-notification-item { 
  font-size: 12px;
  color: rgba(10,35,70,1) !important;
}

.notification-menu-container {
  .MuiList-padding {
    padding: 0 !important;

    .MuiListItem-root {
      display: flex !important;
      align-items: center !important;
    } 
  }
}