.container-row-relatorio-notas {
  gap: 32px;

  & > div:first-of-type {
    width: 190px;
    direction: ltr;
  }
}

.container-header-relatorio-notas,
.container-row-relatorio-notas {
  display: flex;
  .name-column {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 85px;
    padding-left: 32px;
  }
}

.container-relatorio-notas {
  &.MuiGrid-container {
    overflow-x: scroll;
  }
  .accordion-component {
    min-width: 100%;
    width: fit-content;
    .MuiAccordionSummary-root {
      display: block;
    }
  }
  .cell-table {
    width: 85px;
    &:first-of-type {
      width: 154px;
    }
  }
  .MuiTableContainer-root {
    min-height: 0;
  }
}

.height-auto {
  .session-container {
    height: fit-content;
  }
}

@media (min-width: 1100px) {
  .max-width-for-page.table-no-header {
    width: calc(97vw - 220px);
  }
}

.rel-extrato-mf-total {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  p {
    span {
      font-weight: 400 !important;
    }
  }

  p.total-positivo {
    color: #27AE60 !important;
  }

  p.total-negativo {
    color: #BB0000 !important;
  }
}

.rel-extrato-mf-container {
  width: 100%;
}

.rel-extrato-mf-hide-table-footer {
  .table-footer {
    display: none !important;
  }

  p {
    background-color: #F8F8FB;
    border-radius: 5px;
    padding: 8px;
    font-weight: 700;
    width: fit-content;
    align-self: flex-end;
    margin-top: 7px;
    span {
      font-weight: 400 !important;
    }
  }

  p.total-positivo, .table-positive {
    color: #27AE60 !important;
  }

  p.total-negativo, .table-negative {
    color: #BB0000 !important;
  }

  .table-negative, .table-saldo, .table-positive {
    display: block;
    white-space: nowrap;
  }
}

.resumo-mov-container {
  display: flex;

  * {
    color: #3F3F3F;
    font-size: 1rem;
  }

  .small {
    font-size: 0.875rem;
  }

  .laranja {
    color: #EF4066;
  }

  .bold {
    font-weight: 700;
  }

  .data {
    margin: 16px 0;
  }

  >div {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .resumo-mov-title {
    font-size: 1.5rem;
    font-weight: 400;
    color: #EF4066;
  }

  .details {
    gap: 16px;
    > div {
      display: flex;
      justify-content: space-between;

      p:last-child {
        color: #EF4066;
        font-weight: 700;
      }
    }
  }

  .border-before {
    border-top: 1px solid #D9D9D9;
    padding-top: 16px;
  }

  .bigger > * {
    font-size: 1.25rem;
  }
}