@use "../../../../assets/styles/colors";

.page-nfce.emitir-nfce-form-container {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 35px 0;
}

.emitir-nfce-form-title {
  color: colors.$color-secondary;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.emitir-nfce-section-title {
  color: colors.$color-secondary;
  font-size: 24px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.nota-nfce-container {
  background-color: #f8f8fb;
  border-bottom: 1px dotted colors.$color-primary;
  height: min-content !important;
  padding-bottom: 40px;
}

.nota-nfce-info {
  white-space: pre-line;
  word-wrap: break-word;
  margin: 20px 0;
  color: colors.$color-primary;
  text-align: center;
  font-family: Lato Regular;
  font-size: 12px;
  float: right;
  min-height: 60px;
}

.nota-nfce-icon {
  position: relative;
  bottom: 100px;
}

.nota-nfce-icon-wrapper {
  height: 1px !important;
}

.nota-nfce-table {
  color: colors.$color-primary;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  padding: 16px;

  tr {
    height: 48px;
  }

  td {
    text-align: left;
    border-bottom: 0.5px #dfdfe2 solid;
    padding: 8px 3px;
  }

  th {
    text-align: left;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 0.5px #dfdfe2 solid;
  }
}

.table-sem-dados {
  color: #6c6c6c;
  display: block;
  width: 100% !important;
  padding: 20px 0;
  text-align: center;
}

.column-head-id {
  width: 12%;
  text-align: left !important;
  padding-left: 35px;
}

.column-numero {
  width: 12%;
}

.column-quantidade {
  width: 8%;
}

.column-td-valor {
  width: 12%;
  text-align: right !important;
}

.column-th-valor {
  width: 12%;
  text-align: center !important;
}

.column-id {
  width: 12%;
  display: table-cell;
  div {
    display: flex;
    gap: 6px;
    button {
      width: 18px;
      height: 20px;
      border: 1px solid #e8ebf1 !important;
      color: colors.$color-primary;
    }
  }
}

.column-descricao {
  width: 20%;
}

.nota-nfce-total {
  color: colors.$color-primary;
  text-align: right;
  font-weight: 600;
  margin-right: 16px !important;
  margin-bottom: 50px !important;
}

.nota-nfce-info-total-label {
  max-width: 200px !important;
  text-align: right;
}

.nota-nfce-info-total-valor {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding-right: 16px;
  text-align: right;
  color: colors.$color-primary;
}

.nota-nfce-info-total-label {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  margin-right: 2px !important;
  padding-bottom: 3px;
  text-align: left;
  color: colors.$color-primary;
}

.nota-nfce-info-desconto {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding-right: 16px;
  text-align: right;
  color: colors.$color-secondary;
}

.nota-nfce-info-soma-valor {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding: 10px 16px 0 0;
  text-align: right;
  color: colors.$color-secondary;
}

.nota-nfce-info-soma-label {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding: 10px 0 3px 0;
  margin-right: 2px !important;
  text-align: left;
  color: colors.$color-secondary;
}

.emitir-nfce-cadastro-container {
  padding: 20px;
  background-color: #f8f8fb;
  width: calc(100% - 18px) !important;
  margin: 20px auto auto auto !important;
}

.emitir-nfce-cadastro-imagem {
  height: auto;
  width: auto;
  max-width: 180px;
  max-height: 120px;
}

.emitir-nfce-field-quantidade {
  input {
    color: colors.$color-secondary !important;
  }
}

.emitir-nfce-field-desconto {
  padding-left: 6px !important;
  input {
    color: colors.$color-secondary !important;
  }
}

.emitir-nfce-field-total {
  padding-left: 6px !important;
}

.emitir-nfce-btn-adicionar {
  height: 36px;
  width: 36px;
  float: right;
}

.emitir-nfce-pagamento {
  margin-top: 30px;
}

.emitir-nfce-pagamento-label {
  color: colors.$color-primary;
  margin-bottom: 5px !important;
}

.emitir-nfce-pagamento-row {
  max-height: 40px !important;
}

.emitir-nfce-pagamento-text {
  color: #6c6c6c;
  padding-left: 12px !important;
  margin-top: 8px !important;
}

.emitir-nfce-pagamento-valor {
  color: #6c6c6c;
  text-align: right;
  margin-top: 8px !important;
}

.emitir-nfce-pagamento-divider {
  border-bottom: 1px dashed #dfdfe2;
}

.emitir-nfce-submit {
  margin-top: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: flex-end;
}

.nfce-config-info-container {
  margin-top: 30px;
}
