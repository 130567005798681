@use "../../assets/fonts/fonts";
@use "../../assets/styles/colors";

.containerCardNovidades {
  padding: 20px 30px;
  background-size: cover;
  background-image: url("../../assets/img/background-novidades.svg");
  background-position: center;
  background-repeat: no-repeat;

  margin-bottom: 28px;
  border-radius: 10px;
  border: 1px solid colors.$color-white;
  width: 100%;
  .cardNovidades {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    .containerTitulo {
      text-align: center;
      margin-bottom: 10px;
      h4 {
        color: colors.$color-white;
        font-family: fonts.$font-regular;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .containerTexto {
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;

      .descricao {
        span {
          max-width: 166px;
          display: block;
          color: colors.$color-white;
          font-family: fonts.$font-regular;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .containerBotao {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 25px;
        button {
          border-radius: 100px;
          border: none;
          padding: 10px 21px;
          background: colors.$color-white;
          color: colors.$color-secondary;
          cursor: pointer;

          font-family: fonts.$font-regular;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }

    .containerImagem {
      display: flex;
      img {
        max-width: 100%;
      }
    }
  }
}
