@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.account-register-container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
}

.account-register-form {
  padding: 0 1.2rem !important;
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    'empresa empresa empresa empresa empresa empresa'
    'banco banco banco banco banco banco'
    'agencia agencia contaCorrente contaCorrente digito digito'
    'saldoInicial saldoInicial saldoInicial contaContabil contaContabil contaContabil';
}

.account-register-form-empresa-input {
  grid-area: empresa;
}

.account-register-form-banco-input {
  grid-area: banco;
}

.account-register-form-agencia-input {
  grid-area: agencia;
}

.account-register-form-contaCorrente-input {
  grid-area: contaCorrente;
}

.account-register-form-digito-input {
  grid-area: digito;
}

.account-register-form-saldoInicial-input {
  grid-area: saldoInicial;
}

.account-register-form-contaContabil-input {
  grid-area: contaContabil;
}

.zero-margin {
  margin: 16px 0px !important;
  float: right;
}

.button-area {
  button {
    margin: 0px;
  }
}

.form-container {
  width: 75%;
  margin: auto;
}


.nf-header-button {
  position: absolute;
  right: calc(3% - 7px);
  top: 13px;
  z-index: 5;

  button {
    margin: 0;
  }

  ul.visible {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: all 0.4s;

    li {
      display: block;
      padding: 10px;
      height: 100%;
      transition: all 0.4s;
    }
  }

  ul {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: block;
    top: -10px;
    right: 0;
    list-style: none;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    transition: all 0.4s;

    li {
      display: none;
      height: 0;
      padding: 0;
      transition: all 0.4s;

      color: colors.$color-text-primary;
    }

    li:hover {
      background: #eee;
      cursor: pointer;
    }
  }
}

.cadastra-fatura.disabled-field{
  font-family: fonts.$font-regular;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #4A545B;
  min-height: 0px;
  display: flex;
  align-items: center;
}