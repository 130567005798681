@use "../../../assets/styles/colors";

.switchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  div {
    margin: 0;
  }

}

.labelFatura{
  position: relative;
  display: flex;
  align-items: center;
  color: colors.$color-text-primary;
  font-size: 14px;
  line-height: 21.5px;
  margin-bottom: 0.4rem;
}