.solicitacao-servico-container {
  padding: 54px 0px !important;
  height: auto !important;
}

.containerService {
  display: flex;
  max-width: 770px;
  margin: 0 auto;
}

.containerServiceList {
  height: auto !important;
  margin-top: 28px !important;
  padding: 58px 0 !important;
}

.gridXs4 {
  flex-grow: 0;
  max-width: 33.3333% !important;
  flex-basis: 33.3333% !important;
}

.btnAddService {
  max-width: 160px;
  width: 100%;
  max-height: 32px;
  height: auto;
  margin: 0;
}

.serviceTable {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.titleServices {
  color: #053b59;
  font-size: 16px;
  font-weight: 600;
}

.contratar-button-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-top: 25px;

  button {
    margin: 0 !important;
  }
}

.solicitacao-servico-contratar-button-area {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  
  button {
    margin: 0 !important;
    margin-top: 24px !important;
  }
}