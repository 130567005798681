@use "../../../assets/styles/colors";
@use "../../../assets/fonts/fonts";

.text-tipo-cliente-for {
  font-family: fonts.$font-regular;
  font-size: 14px;
  color: colors.$color-secondary;
  font-style: bold;
  margin-left: 20px;
  line-height: 17px;
}
