$font-size-sm: 0.75rem; //12px
$font-size-md: 0.875rem; //14px
$font-size-lg: 1rem; //16px
$font-size-xl: 1.125rem; //18px
$font-size-2xl: 1.25rem; //20px
$font-size-3xl: 1.5rem; //24px
$font-size-4xl: 1.75rem; //28px
$font-size-5xl: 2.25rem; //36px

:export {
  fontSizeSm: $font-size-sm;
  fontSizeMd: $font-size-md;
  fontSizeLg: $font-size-lg;
  fontSizeXl: $font-size-xl;
  fontSize2xl: $font-size-2xl;
  fontSize3xl: $font-size-3xl;
  fontSize4xl: $font-size-4xl;
  fontSize5xl: $font-size-5xl;
}
