@use '../../../assets/styles/colors';

.cadastro-first-title {
  color: colors.$color-secondary;
  font-size: 1rem;
  line-height: 19px;
  margin-bottom: 16px;
}
.cadastro-form-title {
  color: colors.$color-secondary;
  font-size: 1rem;
  line-height: 19px;
  margin-bottom: 16px;
  margin-top: 40px;
}

.view-file-button-label {
  font-size: 14px;
  color: #053b59;
  margin-bottom: 12px;
}

.datepicker {
  margin-top: 3px;
}

.view-file-button-component {
  border-radius: 3px;
  padding: 0.5rem 2.5rem !important;
  margin: 0 !important;
  margin-top: 12px !important;
  font-size: 14px !important;
  font-weight: normal;

  .button-icone {
    position: unset !important;
    top: unset !important;
  }
}

.view-file-button-component:hover {
  border-color: #812990 !important;
  color: #812990 !important;
}

.gov-password-icon-container {
  min-height: 34px;
}

.gov-password-icon {
  z-index: 2;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;

  position: relative;
  left: calc(100% - 2.4rem);
  top: -1.9rem;
}
