@use "../../assets/styles/colors";

.emitir-nf-form-container {
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 1rem 0;
}

.emitir-nf-form-title {
  color: colors.$color-secondary;
  font-size: 1rem;
  line-height: 19px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.emitir-nf-form-subtitle {
  font-size: 14px;
  color: #EF4066;
  font-weight: 600;
  margin-top: -7px;
}

/* SECTION 1 */

.emitir-nf-form-1-section-container {
  display: grid;
  gap: 22px 10px;
  grid-template-areas: "prestador prestador data";
}

.emitir-nf-form-1-section-select {
  grid-area: prestador;
}

.emitir-nf-form-1-section-input {
  grid-area: data;
}

.emitir-nf-form-1-section-input > .icon {
  z-index: 99;
  position: relative;
  left: calc(100% - 30px);
  top: -29px;
}

/* SECTION 2 */

.emitir-nf-form-2-section-fieldset {
  margin-top: -20px;
}

/* SECTION 3 */

.emitir-nf-form-3-section-container {
  display: grid;
  gap: 22px 10px;
  grid-template-areas:
    "estado estado estado cidade cidade cidade"
    "codigo codigo codigo codigo codigo codigo"
    "servico servico servico servico servico servico";
}

.emitir-nf-form-3-section-estado-select {
  grid-area: estado;
}

.emitir-nf-form-3-section-cidade-select {
  grid-area: cidade;
}

.emitir-nf-form-3-section-categoria-select {
  grid-area: categoria;
  margin-bottom: 27px;
}

.emitir-nf-form-3-section-codigo-select {
  grid-area: codigo;
}

.emitir-nf-form-3-section-iss-select {
  grid-area: iss;
}

.emitir-nf-form-3-section-servico-textarea {
  grid-area: servico;
}

/* SECTION 4 */

.emitir-nf-form-4-section-container {
  display: grid;
  gap: 22px 10px;
  grid-template-areas: "nf deducoes desc";
  background-color: #d9e2e6;
  padding: 20px 10px;
  border-radius: 3px;
}

.emitir-nf-form-4-section-nf-input {
  grid-area: nf;
}

.emitir-nf-form-4-section-deducoes-input {
  grid-area: deducoes;
}

.emitir-nf-form-4-section-desc-input {
  grid-area: desc;
}

/* SECTION 5 */

.emitir-nf-form-5-section-container {
  display: grid;
  gap: 22px 10px;
  grid-template-areas:
    "iss cofins csll"
    "pis irpj inss"
    "outros . .";
}

.emitir-nf-form-5-section-iss-input {
  grid-area: iss;
}

.emitir-nf-form-5-section-cofins-input {
  grid-area: cofins;
}

.emitir-nf-form-5-section-csll-input {
  grid-area: csll;
}

.emitir-nf-form-5-section-pis-input {
  grid-area: pis;
}

.emitir-nf-form-5-section-irpj-input {
  grid-area: irpj;
}

.emitir-nf-form-5-section-inss-input {
  grid-area: inss;
}

.emitir-nf-form-5-section-outros-input {
  grid-area: outros;
}

/**/

.emitir-nf-form-submit-button {
  display: flex;
  flex-direction: row-reverse;
}

.emitir-nf-form-submit-button > .btn {
  margin: 2rem 0 0 0 !important;
}
