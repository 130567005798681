@use "../../../../../assets/fonts/fonts";
@use "../../../../../assets/styles/colors";

.text-tipo-empresas {
  font-family: fonts.$font-regular;
  font-size: 14px;
  color: colors.$color-secondary;
  font-style: bold;
  line-height: 17px;
}

.selected-empresas {
  width: 100%;
}

.input-empresas {
  width: 100%;
}

.input-empresas-left {
  width: 100%;
  margin-right: 10px;
}

.input-empresas-area {
  display: flex;
  flex-direction: row;
}

.directBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: -3%;
}

.btnAddRemove {
  padding: 10px 16px;
}

.btnAdd {
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 32px;
}

.cnaeSecundario {
  margin-bottom: 20px;
}

.btnSteps {
  max-width: 120px;
  width: 100%;
  max-height: 32px;
  height: auto;
}

.btnAddAndRemove {
  max-width: 160px;
  width: 100%;
  max-height: 32px;
  height: auto;
  margin-left: auto;
  margin-right: 8px;
  margin-bottom: 20px;
}

.leftButton {
  justify-content: flex-start;
  margin-left: -15px;
}

.required-text {
  color: #6c6c6c;
  font-size: 14px;
}

.hint-form {
  color: #6c6c6c;
}

.inputLabel {
  position: relative;
  margin-bottom: 0.5rem;
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 25px;
}

.empresa-nfe {
  display: flex;
  flex-direction: column;
}

.empresa-nfe-opcoes {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-top: 4px;
}

.align-button-right button{
  margin-left: auto !important;
}

input:disabled {
  cursor: default !important;
}

.controlLabel{ 
  margin-bottom: 0 
}

.itemDiaSemana {
  
}

.containerDiasSemana{
    // padding: 0 1.2rem !important;
    // margin: 0 auto;
    max-width: 100%;
    width: 100%;
    display: grid !important;
    gap: 22px 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;

    input[type=checkbox]{
      display: block;
    }
}

.labelDiaSemana{
  font-family: fonts.$font-regular;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #4A545B;

  display: flex;
  align-items: center;
  gap: 10px;
}

.subSession{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.containerButton{
  margin-left: auto;
  margin-right: 12px;
}

.separadoDigito{
  display: flex;
  align-items: flex-end;
  padding-bottom: 19px !important;
  justify-content: center;
}

.containerButtonArquivos{
  button{
    margin-left: 0;
    margin-top: 10px;
  }
}