@use "../styles/colors";
@use "../fonts/fonts";

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: fonts.$font-regular;
}
body {
  overflow-y: hidden;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.App {
  background-color: #f8f8fb;
  min-height: 100vh;
}
.session-container {
  background-color: colors.$color-white;
  height: 100%;
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 3px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
}
.session-container-header {
  display: flex;
  height: auto;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}
.session-container-header-icon {
  color: colors.$color-secondary;
}
.session-container-header-title {
  margin: 0 0 0 0.7rem;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: colors.$color-primary;
}

.row {
  position: relative;
  display: flex;
  align-items: center;
}

.bloco {
  display: inline-block;
  width: 49%;
}

.mr {
  margin-right: 2%;
}

.left {
  justify-content: flex-start !important;
  margin-left: -20px !important;
}

.btn-step {
  margin-top: 25px;
  margin-bottom: 0;
  padding: 5px 10px;
}

.MuiDropzoneArea-root:focus,
.MuiDropzoneArea-root:hover,
.MuiDropzoneArea-textContainer,
.MuiDropzoneArea-icon {
  border-color: #18a0fb !important;
  fill: #18a0fb !important;
  color: #18a0fb !important;
}

.MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 35px 15px !important;
}

.MuiDropzonePreviewList-image,
.MuiDropzoneArea-root:focus {
  fill: #61befc !important;
  color: #61befc !important;
  border: 1px dashed #61befc !important;
}

.MuiDropzonePreviewList-image {
  height: 70px !important;
}

.MuiGrid-grid-xs-4 {
  max-width: 18% !important;
  flex-basis: 18% !important;
}

.w-full {
  width: 100%;
}

.mr-10 {
  margin-right: 10px;
}

.default-forms-container {
  position: relative;
  background-color: colors.$color-white;
  height: auto;
  padding-bottom: 20px;
  border-radius: 3px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
  min-width: 600px;
}

.DayPickerInput-OverlayWrapper2 {
  position: relative;
}

.DayPickerInput-Overlay2 {
  position: absolute;
  left: 0;
  z-index: 50 !important;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.MuiDialog-root {
  z-index: 9999999 !important;
}

.auto-height {
  div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
    height: fit-content !important;
    max-height: 90%;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .no-print {
    display: none !important;
  }
  .print,
  .print * {
    visibility: visible;
  }
  .print {
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px;
  }
}
