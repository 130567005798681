.modalAlteraSenha {
  // .modal-title {
  //   font-family: fonts.$font-roboto-bold !important;
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 18px !important;
  //   line-height: 21px;
  //   display: flex;
  //   align-items: center;
  //   text-align: center;

  //   /* cor_primaria */

  //   color: #002d45;
  // }
  .input {
    width        : 90% !important;
    margin-left  : 5% !important;
    margin-top   : 2px !important;
    height       : 35px !important;
    margin-bottom: 10px !important;
    border-radius: 6px !important;
  }

  .box-footer {
    align-items    : center !important;
    justify-content: space-between !important;
  }

  .modal-title-area {
    position: relative;
    width   : 400px !important;
  }

  .modal-content {
    padding   : 0px !important;
    margin-top: 30px !important;
    max-width : 400px !important;
  }

  .MuiDialog-paperWidthSm {
    max-width: 400px !important;
  }

  .box-input {
    margin-top: 20px !important;
  }

  // .textarea{
  //     height: 200px !important;
  // }

  .itemResultados {
    display        : flex;
    justify-content: space-between;
    margin-bottom  : 6px;
    background     : #f8f8f8;
    border-radius  : 10px;
    padding        : 10px 15px;

    font-family: "Roboto bold";
    font-style : normal;
    font-weight: 500;
    font-size  : 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: -0.03em;

    /* cor_primaria */

    color: #EF4066;
  }

  .valorItemResultados {
    display    : flex;
    align-items: center;
    font-family: "Roboto regular";
    margin-left: 5px;
  }

  .obsDescricaoItemResultados {
    font-family: "Roboto bold";
    font-style : normal;
    font-weight: 500;
    font-size  : 12px;
    line-height: 12px;
    /* identical to box height, or 200% */

    letter-spacing: -0.03em;

    /* Gray 4 */

    color: #bdbdbd;
  }

  .itemProjecao {
    display      : flex;
    margin-bottom: 6px;

    font-family: "Roboto bold";
    font-style : normal;
    font-weight: 500;
    font-size  : 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: -0.03em;

    /* cor_primaria */

    color: #002d45;
  }

  .tag {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-left    : 10px;

    border-radius: 10px;
    max-height   : 19px;
    padding      : 2px 8px;

    svg {
      font-size  : 10px;
      color      : #580000;
      margin-left: 5px;
    }

    svg.iconeGrande {
      font-size: 12px;
    }
  }

  .tagSuccess {
    background: rgba(111, 207, 151, 0.6);
  }

  .tagDanger {
    background: #fd4040;
  }

  .tagWarning {
    background: rgba(251, 196, 0, 0.6);
  }

  .tagValue {
    font-style : normal;
    font-weight: 500;
    font-size  : 9px;
    line-height: 24px;
    /* identical to box height, or 267% */

    letter-spacing: -0.03em;

    color: #580000;
  }

  .closeModal {
    position: absolute;
    top     : 20px;
    right   : 20px;
    z-index : 1;
  }

  .containerCallback {
    display        : flex;
    align-items    : center;
    justify-content: center;
    margin-top     : 10px;
    margin-bottom  : 20px;
    cursor         : pointer;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .btn-adicionar-parada {
    width     : calc(100%);
    margin-top: 37px;
  }

  .container-formulario {
    padding-bottom: 10px;
    padding-left  : 23px;
    padding-right : 23px;
  }

  .MuiPaper-root.MuiPaper-rounded {
    // padding: 20px 0px !important;
  }

  .password-input-button {
    height: 100%;

    div {
      position: relative;
      // font-size:;

      .password-icon {
        position: absolute;
        top: 5px;
        left: unset;
        right: 0.563rem;
      }
    }

    .password-message {
      color: #812990;
      font-size: 12px;
      border: 1px solid #812990;
      width: 100%;
      outline: none;
      padding: 0.6rem;
      box-sizing: border-box;
      border-radius: 3px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  .password-input div,
  .confirm-password-input div {
    font-family: "Lato Bold";
    font-style : normal;
    font-weight: normal;
    font-size  : 14px;
    line-height: 17px;
    /* identical to box height */

    color: #EF4066;
  }

  .confirm-password-input {
    margin-top: 15px;
  }

  .zero-margin-conta {
    margin: 38px 0px 23px 0px !important;
  }
}