@use "../../assets/styles/colors";

.detalhes-imovel-form-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 35px 35px 35px;
}

.detalhes-imovel-form-title {
  color: colors.$color-secondary;
  font-size: 20px;
  font-weight: 600;
  margin-top: 1.5rem;
  line-height: 19px;
}

.detalhes-imovel-form-subtitle {
  color: colors.$color-secondary;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}

.detalhes-imovel-title {
  color: colors.$color-secondary;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 1.5rem;
}

.detalhes-imovel-title-divider {
  color: colors.$color-secondary;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 5px;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 0.5px #dfdfe2 solid;
}

.detalhes-imovel-text {
  color: colors.$color-text-secundary;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 1rem;
  padding-top: 0 !important;
}

.detalhes-imovel-text-strong {
  font-weight: 600;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 1rem;
  padding-top: 0 !important;
}

.detalhes-imovel-link {
  color: colors.$color-text-primary;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-decoration: none;
  display: block;
  width: fit-content;
}

.detalhes-imovel-image {
  width: 90%;
  border-radius: 20px;
  object-fit: cover;
}

.detalhes-imovel-info {
  display: flex;
  place-content: center;
  div {
    height: 22px;
    line-height: 22px;
    font-weight: 400;
    font-size: 14px;
    color: #4b4b4b;
  }
  svg {
    height: 22px;
    width: 22px;
  }
}

.detalhes-imovel-total-divider {
  border-top: 0.5px #dfdfe2 solid !important;
}

.detalhes-imovel-total-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
}

.detalhes-imovel-total-value {
  color: colors.$color-text-secundary;
  font-size: 14px;
  font-weight: 600;
  line-height: 40px;
}
