@use "../../../../../assets/styles/colors";

.MuiDialog-root {
  background-color: transparent !important;
}

.MuiDialogContent-root .GerenteTimeModalDialogContent {
  overflow-y: hidden !important;
  height: 35vh;
}

.gerente-time-container-title {
  display: flex;
  background-color: colors.$color-white;
  height: 63px;
  align-items: center;
  padding-left: 24px;
}

.title-definicao {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  color: "#4B4B4B";
}

.btn-definirGerente {
  width: 120px;
  height: 32px;
}

.formSelectInputContainer {
  background: transparent !important;
}

div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  height: 500px !important;
}
