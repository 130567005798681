@use '../../../../assets/styles/colors';

.form-cadastro-endereco {
  width: 70% !important;
  margin: auto !important;
}

.title-cadastro-endereco {
  color: colors.$color-secondary;
  font-weight: 600;
  margin-bottom: 20px;
}
