@use "../../assets/fonts/fonts";
@use "../../assets/styles/colors";

.MuiTab-root{
    text-transform: none !important;
}
.MuiTabs-flexContainer {
  border-bottom: 2px solid #e0e0e0;
  color: #EF4066;
}
.MuiTabs-indicator{
    background-color: colors.$color-secondary !important;
}
.MuiTab-wrapper{
    font-family: fonts.$font-regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}
.MuiTab-textColorInherit{
    opacity: 1 !important;
}
.MuiTab-textColorInherit.Mui-selected {
    color: colors.$color-secondary;
}