@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.tableHeaderWeeks {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.tableHeadWeekDayNumber {
  font-weight: 500;
  font-size: 20px;
  color: black;
}

.tableHeadWeekDay {
  font-weight: 400;
  font-size: 14px;
  color: #959595;
}

.rectangleDay {
  max-width: 90px;
  height: 24px;
  margin: 0 auto;
  background-color: #5f5f5f;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: colors.$color-white;
  font-size: 12px;
  font-weight: 400;
}

.rectangleManha {
  max-width: 90px;
  height: 24px;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background-color: #5f5f5f;
    color: colors.$color-white;
    font-size: 12px;
    font-weight: 400;
  }

  div:last-child {
    width: 50%;
    height: 100%;
    background-color: #f3f3f3;
  }
}

.rectangleTarde {
  max-width: 90px;
  height: 24px;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;

  div:first-child {
    width: 50%;
    height: 100%;
    background-color: #f3f3f3;
  }

  div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
    background-color: #5f5f5f;
    color: colors.$color-white;
    font-size: 12px;
    font-weight: 400;
  }
}

.monthWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;

  div:first-child {
    display: flex;
    align-items: center;
  }

  div:last-child {
    margin: 6px auto;
  }
}

.arrowIcon {
  padding: 12px 0px !important;
}

.right-icon {
  margin-left: 4px;
}

.titleMonth {
  font-weight: 400;
  color: #333333;
  font-size: 24px;
}

.MuiPickersToolbar-toolbar {
  background-color: #4f4f4f !important;
}

.MuiButton-textPrimary {
  color: #4f4f4f !important;
}

.MuiFormLabel-root,
.Mui-focused {
  color: #4f4f4f !important;
}

.MuiInputBase-input {
  cursor: pointer;
  text-align: center;
  border-bottom: none !important;
  width: 230px !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}

.MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  max-width: 325px !important;
}

.iconWrapper {
  position: absolute;
  top: 6%;
  left: 90%;
}

.datePickerWrapper {
  display: flex;
  position: relative;
}

.titleWeek {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}