.input-area {
  padding: 0 150px;
  margin-top: 41px;
  align-items: center;
}

.input-children {
  margin-top: 21px;
}

.button-area {
  display: flex;
  justify-content: flex-end;
}
.required-text {
  margin-top: 20px;
}
