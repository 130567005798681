@use "../../assets/styles/colors";

.MuiAutocomplete-input {
  position: relative !important;
  z-index: 1 !important;
  background-color: transparent !important;
  cursor: pointer !important;
  width: 100% !important;
  outline: none !important;
  padding: 0.6rem !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.MuiAutocomplete-root {
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot {
  padding-right: 0;
}

.MuiChip-root {
  max-height: 25px !important;
  padding: 3px 8px !important;
  background: #812990 !important;
  border-radius: 3px !important;
}

.MuiChip-root .icon {
  color: colors.$color-secondary !important;
  margin-right: 0 !important;
  cursor: pointer !important;
}

.MuiChip-label {
  color: #fff;
  padding-left: 0 !important;
}

.MuiAutocomplete-tag {
  margin: 0;
}

.MuiAutocomplete-endAdornment > .MuiIconButton-root {
  color: #6c6c6c !important;
  font-size: 1rem !important;
  left: 5px;
  top: 3px;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.MuiAutocomplete-popupIndicatorOpen {
  top: -0.1rem;
  left: -5px;
}

.MuiInput-underline:before {
  height: 0;
  display: none;
  visibility: hidden;
  border: 0 !important;
}

.MuiInput-underline:after {
  display: none;
  visibility: hidden;
  border: 0 !important;
}

.MuiTouchRipple-root {
  display: none;
}

.MuiIconButton-label .icon {
  margin-top: -4px;
}

.MuiAutocomplete-input{
  font-family: "Lato Regular" !important;
  color: hsl(0,0%,20%) !important;
  line-height: 14.5px !important;
  font-weight: 400 !important;
  font-size: 12px !important;
}

.MuiAutocomplete-popper div{
  font-family: "Lato Regular" !important;
  color: hsl(0,0%,20%) !important;
  line-height: 14.5px !important;
  font-weight: 400 !important;
  font-size: 13px !important;
}