.container-imagem-perfil {
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 24px;

  .imagem-perfil {
    width: 133px;
    height: 133px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f8f8fb;

    img {
      max-width: 100%;
      max-height: 100%;
      width: 133px;
      height: auto;
      border-radius: 50%;
    }
  }

  label.btn {
    max-width: 120px;
    margin-top: 0;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    gap: 12px;
  }
}
