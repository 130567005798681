@use "../../../assets/styles/colors";
@use "../../../assets/fonts/fonts";

.headerButton {
  position: absolute;
  right: calc(3% - 7px);
  top: 13px;
  z-index: 5;

  button {
    margin: 0;
  }
}

.marginLeftAuto {
  margin-left: auto;
}

.containerHeaderButton {
  display: flex;
}

.btnPreCadastro {
  margin-right: 16px !important;
  background-color: #ef4066 !important;
}
.avisoInativar {
  color: colors.$color-danger;
  text-align: center;
  font-family: fonts.$font-regular;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
