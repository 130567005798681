.filter-button-area {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  button {
    margin: 0 !important;
    margin-right: 8px !important;
    margin-top: 30px !important;
    width: 150px !important;
  }
}

.custom-filter-container {
  background: #000;
}

.status-label {
  color: #fff !important;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}

.container-default-component  .filter-container:not(.relatorio-assinantes){
  justify-content: flex-end !important;
}