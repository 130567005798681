@use '../../assets/styles/colors';

.formSelectContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.formSelectLabel {
  margin-bottom: 0.5rem;
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 17px;
}

.formRequiredItem {
  margin: 0 0.1rem;
}
.formSelectHint {
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  color: #6c6c6c;
  background-color: #ffffff;
  position: absolute;
  top: 35px;
  left: 30px;
  z-index: 1;
  transition: all 0.1s;
}
.formSelectHintHide {
  opacity: 0;
}
.formSelectAnimate {
  top: 1.9rem !important;
  z-index: 3 !important;
  font-style: normal;
  color: #424242;
  opacity: 1 !important;
}
.formSelectTableContainer {
  position: absolute;
  top: 1.5rem;
  width: 100%;
  background-color: #ffffff;
  z-index: 0;
  opacity: 0;
  transition: all 0.1s;
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  padding: 0.3rem;
  pointer-events: none;
}
.formSelectTableContainerShow {
  z-index: 2 !important;
  opacity: 1 !important;
  top: 1.5rem;
  border-color: #66afe9;
  pointer-events: all !important;
}
.formSelectTableInput {
  margin-top: 2rem;
  border-color: #d4d4d4;
}
.formSelectTableInput,
.formSelectInput {
  width: 100%;
  outline: none;
  padding: 0.7rem 1.8rem;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: transparent;
  position: relative;
  z-index: 1;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.formSelectUnderlineInput {
  width: 100%;
  outline: none;
  padding: 0.6rem;
  border: none;
  border-bottom: 1px solid colors.$color-primary;
  box-sizing: border-box;
  background-color: transparent !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.formSelectTableList {
  padding: 0;
  margin: 0;
  height: 14.4rem;
  overflow-y: auto;
}
.formSelectTableListItem {
  list-style: none;
  color: #424242;
  font-size: 0.9rem;
  padding: 0.5rem 0.3rem;
  cursor: pointer;
  box-sizing: border-box;
}
.formSelectTableListItem:hover {
  background-color: #d4d4d4;
}
.formSelectInputContainer {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  color: #4b4b4b;
}
.formSelectInputArrow {
  position: absolute;
  right: 10px;
  color: colors.$color-primary;
}
.formMarginTop {
  margin-top: 30px;
}

.selectUnderlineContainer {
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #4b4b4b;
}

.selectUnderlineHint {
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  color: #6c6c6c;
  background-color: transparent;
  position: absolute;
  top: 2.1rem;
  left: 0.6rem;
  z-index: 1;
  transition: all 0.1s;
}

.inputWrapper {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  div {
    position: absolute !important;
    margin: 11px 0 0 6px;
    color: colors.$color-secondary;
  }
}

.formCardItem {
  padding: 10px 16px;
  margin-top: 14px;
  display: flex;
  align-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: colors.$color-primary;
  border: 1px solid #edf1f7;
  box-sizing: border-box;
  filter: drop-shadow(0px 8px 24px rgba(25, 32, 56, 0.04));
  border-radius: 8px;

  img {
    width: 75px;
    height: 54px;
  }
}

.formCardTitleWrapper {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: left;
  place-self: center;
}

.formCardSubtitle {
  color: #6c6c6c;
}

.formCardDescription {
  width: 40%;
  padding-left: 8px;
  place-self: center;
}

.formCardValue {
  padding-left: 8px;
  text-align: right;
  width: 20%;
  place-self: center;
}
