.cadastro-certidao-form-container {
  margin: 0 auto !important;
  max-width: 770px !important;
  width: 100% !important;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "certidao orgaoemissor"
    "abrangencia status"
    "emissao vencimento"
    "empresa empresa"
    "anexo anexo";
}

.cadastro-certidao-certidao-select {
  grid-area: certidao;
}

.cadastro-certidao-orgaoemissor-select {
  grid-area: orgaoemissor;
}

.cadastro-certidao-abrangencia-select {
  grid-area: abrangencia;
}

.cadastro-certidao-status-select {
  grid-area: status;
}

.cadastro-certidao-emissao-input {
  grid-area: emissao;
}

.cadastro-certidao-vencimento-input {
  grid-area: vencimento;
}

.cadastro-certidao-empresa-select {
  grid-area: empresa;
}

.cadastro-certidao-anexo-input {
  grid-area: anexo;
}

.cadastro-certidao-anexo-visualizar {
  grid-area: anexo;
  height: 2px;
  display: flex;
  direction: column;
  margin: 0;
}
.cadastro-certidao-anexo-botoes {
  display: flex;
}
.text-button {
  margin: 0;
}

.icon-button {
  margin-left: 10px;
}

.emitir-nf-form-submit-button {
  display: flex;
  flex-direction: row-reverse;
}

.required-text {
  margin-top: 20px;
}
