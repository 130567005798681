@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

:root{
    --navbar-item-width: 100%;
    --navbar-item-height: auto;
    --navbar-item-text-color: #0A2346;
    --navbar-item-text-font-family: fonts.$font-regular;
    --navbar-item-bg-first-color:#FFFFFF;
    --navbar-item-bg-second-color:#F7F7FE;
}
.navbar-item{
    width: var(--navbar-item-width);
    min-height: 46px;
     max-height: var(--navbar-item-height);
    box-sizing: border-box;
    display: flex;    
    align-items: center;
    position: relative;
    cursor: pointer;
    color: var(--navbar-item-text-color);
    font-family: var(--navbar-item-text-font-family);
    font-size: 14px;
    line-height: 17px;
    background-color: var(--navbar-item-bg-color);
}
.navbar-item-open-mark{
    background-color: var(--navbar-item-bg-second-color);
    font-weight: 600;
}
.navbar-item-open-mark::before{
    content: "";
    position: absolute;
    left: 0;
    height: 40px;
    border: 3px solid colors.$color-secondary;
}
.navbar-item-vertical-children-folder.navbar-item-open-mark{
    background-color: transparent;
}
.navbar-item-vertical-children-folder.navbar-item-open-mark::before{
    content: "";
    position: absolute;
    left: -7px;
    height: 0;
    border: none;
}

button.navbar-link.navbar-link-container {
    background: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    padding: 0;
}

.navbar-link{
    text-decoration: none;
}
.navbar-link-container{
    display: flex; 
    width: 100%;
}
.navbar-item-hover:hover, .navbar-item-hover:focus{
    background-color: var(--navbar-item-bg-second-color);
}
.navbar-item-wrap{
    display: flex;    
    align-items: center;
}
.navbar-item-wrap p{
    margin-left: 9px;
}
.navbar-item-icon{
    display: flex;    
    align-items: center;
}
.navbar-item-icon p{
    margin-left: 8px;
}
.navbar-item-icon-folder-horizontal{
    position: absolute;
    top: 3rem
}
.navbar-item-icon-folder-vertical{
    height: 100%;
    display: inherit;
    align-items: center;
}
.navbar-collapse-item{
    -webkit-transition: -webkit-transform .1s ease-in-out;
    -ms-transition: -ms-transform .1s ease-in-out;
    transition: transform .1s ease-in-out; 
}
.navbar-close-folder{
    transform:rotate(-88deg);
    -ms-transform:rotate(-88deg);
    -webkit-transform:rotate(-88deg);
}
.navbar-open-folder{
    transform:rotate(0deg);
    -ms-transform:rotate(0deg);
    -webkit-transform:rotate(0deg);
}
.navbar-item-horizontal{
    height: 100%;
    justify-content: center;
}
.navbar-item-vertical-parent{
    padding: 0 19.13px 0 28px;
}
.navbar-item-vertical-children{
    padding: 0 .5rem 0 0;
}
.navbar-item-horizontal-border{
    border-right: 1px solid #E7E8EC;
    border-bottom: 1px solid #E7E8EC;
}
.navbar-item-folder-horizontal{
    justify-content: center;
}
.navbar-item-folder-vertical{
    justify-content: space-between;
}
.navbar-list{
    width: 100%;
}
.navbar-list ul{
    margin: 0;
    padding: 0;
    width: 100.7%;
    position: absolute;
    left: 0;
    top: 4rem;
    z-index: 1;
}
.navbar-list-vertical{
    display: block;
}
.navbar-list-vertical ul{
    position: relative;
    top: 0;
}
.navbar-show-folder{
    display: block;
}
.navbar-hide-folder{
    display: none;
}
.navbar-show-folder .navbar-item-horizontal{
    justify-content: flex-start;
    padding: 0 .5rem;
}