.modal-container {
  background-color: white;
  padding: 0 !important;
}
.title {
  font-size: 16px;
  color: #EF4066;
  font-weight: 600;
}

.modal-title-container {
  margin: 23px 0 23px 30px;
  padding: 23px 30px;
}

.content {
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px;
}

.input-read-only {
  background: #f8f8fb;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
  padding: 6px;
  color: #4b4b4b;
  margin-top: 8px;
}

.margin-zero {
  margin: 0;
}
