.iframeFullHeight {
  height: 620px;
}

@media (max-width: 1100px) {
  iframe:not(#fb-root iframe) {
    position: absolute;
    top: 350px;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (max-width: 760px) {
  .iframeFullHeight {
    height: 88vh;
  }

  iframe:not(#fb-root iframe) {
    max-height: 400px !important;
  }
}
