@use "../../../assets/fonts/fonts";
@use "../../../assets/styles/colors";

.text-tipo-empresas {
  font-family: fonts.$font-regular;
  font-size: 14px;
  color: colors.$color-secondary;
  font-style: bold;
  line-height: 17px;
}

.selected-empresas {
  width: 100%;
}

.input-empresas {
  width: 100%;
}

.input-empresas-left {
  width: 100%;
  margin-right: 10px;
}

.input-empresas-area {
  display: flex;
  flex-direction: row;
}

.directBtn {
  display: flex;
  justify-content: flex-end;
  margin-right: -3%;
}

.btnAddRemove {
  padding: 10px 16px;
}

.btnAddEmpresa {
  max-width: 150px;
  width: 100%;
  height: auto;
  max-height: 32px;
}

.cnaeSecundario {
  margin-bottom: 20px;
}

.btnSteps {
  // max-width: 120px;
  width: 100%;
  max-height: 32px;
  height: auto;
}

.btnAddAndRemove {
  margin-top: 20px;
  max-width: 160px;
  width: 100%;
  max-height: 32px;
  height: auto;
}

.leftButton {
  justify-content: flex-start;
  margin-left: -15px;
}

.required-text {
  color: #6c6c6c;
  font-size: 14px;
}

.hint-form {
  color: #6c6c6c;
}

.inputLabel {
  position: relative;
  margin-bottom: 0.5rem;
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 25px;
}

.empresa-nfe {
  display: flex;
  flex-direction: column;
}

.empresa-nfe-opcoes {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-top: 4px;
}

.align-button-right button{
  margin-left: auto !important;
}

input:disabled {
  cursor: default !important;
}

.containerFormMobile{
  margin-top: 20px;
  background: colors.$color-white;
  padding-right: 10px;
  max-width: 100vw !important;
  padding-top: 30px !important;
  box-shadow: none !important;
}

.accordionStyles{
  box-shadow: none;
  background-color: colors.$color-white !important;
  margin-top: 0 !important;
  border: solid 1px rgba(0, 0, 0, 0.2) !important;

  .no-boder-top{
    border-top: none;
  }
}

.accordionSummary{
  padding-inline: 10px;
  input[type=checkbox] {
    display: block;
}
}

.accordionDetails{
  max-width: 100%;
  margin: 0 !important;
  background: colors.$color-white;
  display: block !important;
}

.btnOutline{
  border: 1px solid #812990 !important;
  color: #812990;
  background-color: white;
  box-sizing: border-box;
  padding: 1em 2em;
  margin: auto 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  border-radius: 3px;
  width: 150px;
  background-color: #f8f8f8 !important;
}

.btnSalvar{
  margin: 0;
}

.customLabel{
  div{
    margin-bottom: 0;
  }
  label{
    color: #812990 !important;
  }
}

.warning{
  color: #812990 !important;
  padding: 15px 8px;
  font-size: 14px;
}

.mtDp{
  margin-top: 30px;
}