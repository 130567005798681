.container {
}

.errorList {
  list-style: none;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-right: 10rem;
}

/* .message {
  padding-right: 10rem;
} */

