@use "../../assets/styles/colors";

:root {
  --darkBlue: #EF4066;
  --lightBackground: #f8f8fb;
  --lightBorder: #e8ebf1;
  --darkGrey: #4b4b4b;
}

.body {
  margin-top: 32px;
}

.space-vertical {
  height: 12px;
}

.space-large-vertical {
  margin-bottom: 45px;
}

.space-vertical-xl {
  height: 40px;
}
.label {
  color: var(--darkBlue);
  font-size: 14px;
}

.font-title {
  color: var(--darkBlue);
  font-weight: 600;
}

.text-background {
  margin-top: 7px;
  background-color: var(--lightBackground);
  border: 1px solid var(--lightBorder);
  color: var(--darkGrey);
  padding: 6px;
}

.text-pleft {
  padding-left: 6px;
}
.text-mleft {
  margin-left: 6px;
}

.font-orange {
  color: colors.$color-secondary;
}

.font-darkBlue {
  color: var(--darkBlue);
}

.background-light {
  background-color: var(--lightBackground);
}

.font-small {
  font-size: 2px;
}
.icon-wrapper {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  height: 15px;
  width: 15px;
}

.input-conta {
  width: 100px;
}

.session-categoria-padrao-responsive .search-input-grid-container{
  margin-top: calc(-1rem - 1% - 96px);
  z-index: 10;

  @media(max-width: 1100px){
    margin-top: calc(-1rem - 1% - 160px);
  }
}