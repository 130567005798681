.info {
  width  : 100%;
  padding: 10px;

  span {
    font-size  : 14px;
    line-height: 17px;
    color      : #4a545b;
    height     : 40px;
  }

  margin       : 5px 0px 20px 0px;
  border-bottom: 2px dashed rgba(5, 59, 89, 0.24);
}

.session-container-form {
  .select-container {
    >div:nth-child(2) {
      border       : 1px solid #c8c8c8;
      border-radius: 4px;
    }
  }
}

.container-table-details {
  border-bottom: 1px solid #D7D7D7;
  border-top   : 1px solid #D7D7D7;
}

.input-checkbox-finances {
  display    : flex;
  align-items: center;

  margin-left: 15px;

  span {
    width: 100%;
  }

  input {
    width: 20px !important;
  }
}

.title-services {
  padding: 10px;

  span {
    font-size  : 16px;
    line-height: 19px;
    color      : #812990;
  }
}

.container-input-switch {
  display    : flex;
  align-items: center;
  color      : #053b59;
  font-size  : 14px;
}

.directBtn {
  display        : flex;
  justify-content: flex-end;
  margin-top     : 20px;
  margin-bottom  : 10px;
}

.btnRemove {
  display        : flex;
  justify-content: flex-end;
  width          : 100%;

  button {
    width        : 150px;
    margin-top   : 10px;
    margin-bottom: 20px;
    height       : 32px;
    margin-right : 10px;
  }
}

.modal-items-detail {
  div {
    display       : flex;
    flex-direction: column;

    >label {
      margin: 0 !important;
    }

    >span {
      font-size: 14px;
      color    : #4b4b4b;
    }
  }

}

.wrapper-detail {
  width: 100%;
}

.title-section {
  label {
    font-style : normal;
    font-weight: normal;
    font-size  : 18px;
    line-height: 22px;
  }
}

.top-info-details {
  display              : grid;
  grid-template-columns: 1.6fr 1fr;
  width                : 100%;
  padding-bottom       : 15px;
  border-bottom        : 1px solid #D7D7D7;
}

.info-itens-container {
  padding-top     : 25px;
  // border-bottom: none !important;
}

.border-bottom {
  border-bottom: 1px solid #D7D7D7 !important;
  margin-bottom: 30px !important;
}

.total-item-caixa {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  margin-bottom  : 10px;

  h3 {
    font-style : normal;
    font-weight: bold;
    font-size  : 16px;
    line-height: 19px;

    /* Cinza */

    color: #4A545B;
  }

  span {
    font-style : normal;
    font-weight: bold;
    font-size  : 16px;
    line-height: 19px;

    /* Cinza */

    color: #4A545B;
  }
}

.info-top-payment {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  margin-bottom  : 10px;
  padding        : 0 10px;

  h3 {
    font-style : normal;
    font-weight: 600;
    font-size  : 14px;
    line-height: 17px;
    color      : #005E78;
  }

  span {
    font-style : normal;
    font-weight: normal;
    font-size  : 14px;
    line-height: 17px;
    color      : #005E78;
  }
}

.info-item-payment {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  margin-bottom  : 10px;
  padding        : 0;

  h3 {
    font-style : normal;
    font-weight: 600;
    font-size  : 14px;
    line-height: 17px;
    color      : #4A545B;
  }

  span {
    font-style : normal;
    font-weight: normal;
    font-size  : 14px;
    line-height: 17px;
    color      : #4A545B;
  }
}

.total-value {

  h3,
  span {
    color      : #EF4066 !important;
    text-align : right;
    font-weight: 600;
    font-size  : 20px;
  }

  // margin-right: 16px !important;
  // margin-bottom: 50px !important;
}