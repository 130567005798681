@use "../../assets/fonts/fonts";
@use "../../assets/styles/colors";

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.inputLabel {
  position: relative;
  margin-bottom: 0.5rem;
  color: colors.$color-text-primary !important;
  font-size: 14px;
  line-height: 25px;
}
.inputLabelCeu {
  position: relative;
  display: flex;
  align-items: center;
  font-family: fonts.$font-bold !important;
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 21.5px;
  font-weight: 700;
  margin-bottom: 0.4rem;
}
.inputCeu {
  font-family: fonts.$font-regular !important;
  color: colors.$color-primary;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
}
.inputLabelCeuInterno {
  font-family: fonts.$font-regular !important;
  color: colors.$color-primary;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
  background-color: transparent;
  position: relative;
  z-index: 1;
  color: #6c6c6c;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  outline: none;
  padding: 0.6rem;
  border: 1px solid #e8ebf1;
  background-color: white;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  margin-top: 2px;
}
.input {
  background-color: transparent;
  position: relative;
  z-index: 1;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  outline: none;
  padding: 0.6rem;
  border: 1px solid #c8c8c8 !important;
  background-color: white;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  margin-top: 2px;
}

.requiredItem {
  margin: 0 0.1rem;
}
.inputHint {
  font-size: 14px;
  line-height: 17px;
  font-style: italic;
  color: #6c6c6c;
  background-color: #ffffff;
  position: absolute;
  display: inline-table;
  top: 2.1rem;
  left: 0.6rem;
  width: 100%;
  z-index: 1;
  transition: all 0.1s;
}

.inputHintHide {
  opacity: 0;
}
.inputAnimate {
  top: 1.9rem !important;
  z-index: 3 !important;
  font-style: normal;
  color: #424242;
  opacity: 1 !important;
}
.input {
  width: 100%;
  outline: none;
  padding: 0.6rem;
  border: 1px solid #e8ebf1;
  background-color: white;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  height: 6rem;
  margin-top: 2px;
}
.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}
