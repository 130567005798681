@use "../../assets/styles/_colors.scss";
@use '../../assets/fonts/fonts';

.paper {
  min-width: 400;
  min-height: 178px;
  text-align: left;
  align-items: center;

  &.with-date{
    .MuiDialog-container.MuiDialog-scrollPaper{
      div:first-child{
      overflow-y: visible;
      }
    }

    .custom-dialog-content{
      overflow-y: visible;
    }
  }
}
.buttonContent {
  padding-bottom: 16px !important;
}

.titleConfirmDialog {
  font-size: 16px;
  font-family: fonts.$font-regular;
  color: colors.$color-primary;
  font-weight: 600;
  letter-spacing: 0.9px;
}

.btnSize {
  min-width: 120px !important;
  height: 32px !important;
}

.confirmDescription {
  font-family: fonts.$font-regular;
  font-size: 14px;
  color: colors.$color-text-primary;
  white-space: pre-wrap;
  word-break: keep-all;
}

.iconConfirm {
  color: colors.$color-primary;
  padding: 0;
  font-family: fonts.$font-regular;
}

.custom-dialog-content{
  padding-top: 40px !important;
  padding-bottom: 30px !important;
}

.dialog-title-custom-width{
  max-width: 100%;
}

.container-form-dates{
  margin-top: 25px !important;
}