.row {
  span {
    color: #EF4066;
    font-size: 14px;
    font-weight: 700;
  }
}

.alignRight {
  text-align: right !important;
}

.iconsCell {
  svg {
    margin-bottom: 7px;
  }
}

.editIcon {
  svg {
    stroke: #812990;
    height: 19px;
  }
}

.descricaoField {
  display: flex;
  flex-direction: column;
}

.observacoes {
  margin-top: 8px;
}
