@use "../../assets/styles/colors";

.container {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0 !important;
  position: relative;
  background-color: #f8f8fb;
  padding: 20px;
  border-radius: 3px;
  border: solid lavender 2px;
  /* width: 300px;
  margin: 50px auto;
  border: 4px solid #00bfb6;
  padding: 20px;
  text-align: center;
  font-weight: 900;
  color: #00bfb6;
  font-family: arial;
  position: relative; */
}

.chat:before {
  /* content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #f8f8fb;
  left: 250px;
  top: -40px; */
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid lavender;
  right: 58%;
  top: -40px;
}

.chat:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid #f8f8fb;
  right: 58%;
  top: -37px;
}

.inputCheckContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.checkBoxContainer {
  margin-left: 0.7rem;
  margin-top: -1.6rem;
  margin-right: 0.5rem;
}
.checkBoxTextContainer {
}
.checkBoxTextContainer > span {
  font-size: 14px;
  color: #4d4d4d;
  display: inline-block;
}
.fieldTitle {
  font-weight: 600;
  color: #EF4066;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 20px;
}
.selectContainer {
  max-width: 50% !important;
  position: relative;
  width: 100% !important;
  margin: 40px 0;
  z-index: 99999;
}
.selectContainer > div > div {
  background: #fff;
}
.parcelContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.containerTituloParcela > h1 {
  color: colors.$color-secondary;
  font-size: 18px;
  font-weight: 600;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  position: relative;
  top: 1.3rem;
  min-width: 25px;
}

.parcelContainerInputsContainer {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 10px;
}
