@use "../../../assets/styles/colors";

.nfHeaderButton {
  position: absolute;
  right: calc(3% - 7px);
  top: 13px;
  z-index: 5;

  button {
    margin: 0;
  }

  ul.visible {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: all 0.4s;

    li {
      display: block;
      padding: 10px;
      height: 100%;
      transition: all 0.4s;
    }
  }

  ul {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    display: block;
    top: -10px;
    right: 0;
    list-style: none;
    background: #fff;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    transition: all 0.4s;

    li {
      display: none;
      height: 0;
      padding: 0;
      transition: all 0.4s;

      color: colors.$color-text-primary;
    }

    li:hover {
      background: #eee;
      cursor: pointer;
    }
  }
}

.statusLabel {
  color: #fff;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
}
