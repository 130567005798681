@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.button-icon {
  margin-right: 0.5rem;
}

.button-icone {
  margin-bottom: 5px;
}

.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn {
  font-family: fonts.$font-regular;
  padding: 1em 2em;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  box-sizing: border-box;
  margin: auto 3%;
  transition: all 300ms ease-in-out;
}
.flat-default {
  color: #ffffff;
  background-color: colors.$color-secondary;
}
.flat-default:hover {
  background-color: colors.$color-primary;
  transition: all 300ms ease-in-out;
}
.flat-default-strong {
  color: colors.$color-white;
  background-color: #EF4066;
}
.btn-primary {
  color: #ffffff;
  background-color: colors.$color-secondary;
  // background-color: colors.$color-primary;
  border-radius: 3px;
}
.btn-primary:hover {
  background-color: #EF4066;
  transition: all 300ms ease-in-out;
}
.btn-dark {
  color: colors.$color-white;
  background-color: #EF4066;
  border-radius: 3px;
}
.btn-success {
  background-color: colors.$color-secondary;
  color: white;
  border-radius: 3px;
  padding: 0.9em 4em !important;
}
.btn-success:hover {
  background-color: colors.$color-primary;
  transition: all 300ms ease-in-out;
}
.btn-success-strong {
  background-color: #62974b;
  color: white;
  border-radius: 3px;
}
.btn-warning {
  color: #ffffff;
  background-color: colors.$color-secondary;
  border-radius: 3px;
}
.btn-warning:hover {
  background-color: #EF4066;
  transition: all 300ms ease-in-out;
}
.btn-danger {
  background-color: #bb0000;
  color: white;
  border-radius: 3px;
}
.btn-danger:hover {
  background-color: #960000;
  transition: all 300ms ease-in-out;
}
.btn-danger-strong {
  background-color: #960000;
  color: white;
  border-radius: 3px;
}
.btn-info {
  background-color: colors.$color-secondary;
  border-radius: 3px;
  color: white;
}
.btn-info:hover {
  background-color: #EF4066;
  transition: all 300ms ease-in-out;
}
.btn-info-strong {
  background-color: colors.$color-secondary;
  border-radius: 3px;
  color: white;
}
.btn-secondary {
  background-color: colors.$color-secondary;
  border-radius: 3px;
  color: white;
}
.btn-secondary:hover {
  background-color: #EF4066;
  transition: all 300ms ease-in-out;
}
.btn-secondary-strong {
  background-color: colors.$color-secondary;
  border-radius: 3px;
  color: white;
}
.btn-simple {
  border: 1px solid #e8ebf1;
  font-size: 10.33px;
  color: #EF4066;
}
.btn-simple:hover {
  border: 1px solid #EF4066;
  transition: all 300ms ease-in-out;
}
.default-outline {
  border: 1px solid colors.$color-secondary !important;
  color: colors.$color-secondary;
  background-color: transparent;
}
.default-outline:hover {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  transition: all 300ms ease-in-out;
}
.default-outline-strong {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  background-color: transparent;
}
.secondary-outline {
  border: 1px solid #6c6c6c !important;
  color: #6c6c6c;
  border-radius: 3px;
  background-color: transparent;
}
.secondary-outline:hover {
  border: 1px solid #1d1d1d !important;
  color: #1d1d1d;
  transition: all 300ms ease-in-out;
}
.action-outline {
  border: 1px solid #6c6c6c !important;
  color: #6c6c6c;
  border-radius: 3px;
  background-color: transparent;
  font-size: 16px;
  padding: 5px 10px;
}
.action-outline:hover {
  border: 1px solid #1d1d1d !important;
  color: #1d1d1d;
  transition: all 300ms ease-in-out;
}
.info-outline-small {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  border-radius: 3px;
  background-color: transparent;
  font-size: 16px;
  padding: 5px 10px;
  font-weight: 500 !important;
  text-shadow: 0.4px 0 0px #EF4066 !important;
}
.warning-outline {
  border: 1px solid colors.$color-secondary !important;
  color: colors.$color-secondary;
  border-radius: 3px;
  background-color: transparent;
}
.warning-outline:hover {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  transition: all 300ms ease-in-out;
}
.warning-outline:hover svg {
  color: #EF4066 !important;
  transition: all 300ms ease-in-out;
}

.info-outline {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  border-radius: 3px;
  background-color: transparent;
}
.primary-outline {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  border: 1px solid colors.$color-secondary !important;
  color: colors.$color-secondary;
  border-radius: 3px;
  background-color: transparent;
}
.primary-outline:hover {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  transition: all 300ms ease-in-out;
}
.primary-outline-small {
  border: 1px solid colors.$color-secondary !important;
  color: colors.$color-secondary;
  border-radius: 3px;
  background-color: transparent;
  font-size: 16px;
  padding: 5px 10px;
}
.primary-outline-small:hover {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  transition: all 300ms ease-in-out;
}
.primary-outline:hover svg {
  color: #EF4066 !important;
  transition: all 300ms ease-in-out;
}

.primary-outline-strong {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
  border-radius: 3px;
  background-color: transparent;
}
.success-outline {
  border: 1px solid #8ac64b !important;
  color: #8ac64b;
  border-radius: 3px;
  background-color: transparent;
}
.success-outline:hover {
  border: 1px solid #62974b !important;
  color: #62974b;
  transition: all 300ms ease-in-out;
}
.success-outline-strong {
  border: 1px solid #62974b !important;
  color: #62974b;
  border-radius: 3px;
  background-color: transparent;
}
.simple-outline {
  border: 1px solid #EF4066 !important;
  font-size: 10.33px;
  color: #EF4066;
  background-color: transparent;
}

.btnHidden {
  visibility: hidden;
}

.outline-no-hover {
  border: 1px solid #812990 !important;
  color: #812990;
  border-radius: 3px;
  background-color: transparent;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: normal;
  margin: 0 !important;
}

.icon-right {
  flex-direction: row-reverse !important;
  padding: 1em 1.5em;

  span.button-icone {
    top: -3px;
    margin: 0;

    div.icon {
      margin: 0;
      margin-left: 1em;
    }
  }
}
