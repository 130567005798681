.account-register-container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
}

.account-register-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "empresa empresa empresa empresa empresa empresa"
    "banco banco banco banco banco banco"
    "agencia agencia contaCorrente contaCorrente digito digito"
    "saldoInicial saldoInicial saldoInicial contaContabil contaContabil contaContabil"
    "espaco espaco espaco espaco espaco salvar";
}

.account-register-form-empresa-input {
  grid-area: empresa;
}

.account-register-form-banco-input {
  grid-area: banco;
}

.account-register-form-agencia-input {
  grid-area: agencia;
}

.account-register-form-contaCorrente-input {
  grid-area: contaCorrente;
}

.account-register-form-digito-input {
  grid-area: digito;
}

.account-register-form-saldoInicial-input {
  grid-area: saldoInicial;
}

.account-register-form-contaContabil-input {
  grid-area: contaContabil;
}

.align-right {
  grid-area: salvar;
}
