@use "../../assets/styles/colors";

.calendar-wrapper {
  height: 60vh;
  // background: white;
}
.calendar-toolbar {
  padding: 10px 0 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid black !important;
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: transparent !important;
}

.rbc-month-view {
  border: none !important;
  padding-left: 10px;
}

.rbc-month-header {
  div {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
}

.rbc-header {
  border: none !important;
}

.rbc-row-bg {
  background-color: #f3f3f3 !important;
}

.rbc-day-bg {
  margin-right: 10px !important;
}

.rbc-date-cell {
  a {
    pointer-events: none !important;
    cursor: default !important;
  }
}

.rbc-date-cell {
  text-align: center !important;
  font-size: 24px;
  color: #d3d3d3;
}

.rbc-month-row {
  text-align: center !important;
  flex-direction: column !important;
  place-content: center !important;
  border: none !important;
  margin-bottom: 10px !important;
  z-index: 0 !important;
}

.rbc-event {
  background-color: colors.$color-primary !important;
  width: calc(100% - 10px) !important;
  height: 20px !important;
  z-index: 0 !important;
  position: relative;
}

.rbc-calendar {
  align-items: center !important;
  margin: 0 auto !important;
}

.calendar-toolbar-title {
  margin-left: 6px;
  font-weight: 300 !important;
}

.wrapperDia {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  color: colors.$color-white;
  font-weight: 400;
}

.wrapperTarde {
  position: absolute;
  padding: 5px;
  right: 0px;
  bottom: 0px;
  color: colors.$color-white;
  font-weight: 400;
}

.wrapperManha {
  position: absolute;
  padding: 5px;
  color: colors.$color-white;
  font-weight: 400;
}
