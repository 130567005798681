.navbar {
  width: 100%;
  display: flex;
  align-items: center;
}
.vertical {
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 290px);

  /* firefox scrollbar width */
  scrollbar-width: thin;
}
.horizontal {
  height: 4rem;
}

/* scrollbar Google, Safari */
::-webkit-scrollbar {
  width: 6px;
  background: #f0f0f0;
}
::-webkit-scrollbar-thumb {
  background: #dad7d7;
}
