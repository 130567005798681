@use "../../assets/styles/colors";

.switch-label-active {  
  font-size: 14px;
  font-weight: bold;
  color: colors.$color-secondary;
  margin: 4px 10px 0 0;
}

.switch-label-inactive {  
  font-size: 14px;
  font-weight: bold;
  color: colors.$color-text-secundary;
  margin: 4px 10px 0 0;
}

.switch-row {
  width: 100%;  
  display: flex;
  justify-content: center;
}

.label-center {  
  text-align: center;
}