
.accordionSummaryDiaSemana{
  span{
    display: flex;
    gap: 10px;
  }
}

.containerDiasSemana{
  max-width: 100%;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-columns: 1fr 1fr !important;
  grid-template-rows: 1fr 1fr !important;
}