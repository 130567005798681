.formContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 770px;
}

h1.sectionTitle {
  font-size: 16px;
  font-weight: normal;
  color: #812990;
  font-weight: 600;
  margin-bottom: 38px;
}

div.sectionOneHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

div.switchArea {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.switchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

span.switchLabel {
  margin-right: 20px;
  font-size: 14px;
  color: #EF4066;
}

span.false {
  margin-right: 12px;
  font-weight: bold;
  color: #6c6c6c;
  font-size: 14px;
}

span.true {
  margin-right: 12px;
  font-weight: bold;
  color: #EF4066;
  font-size: 14px;
}

div.sectionOneFields > div span {
  max-width: inherit;
  align-items: center;
  top: 2.2rem;
}

div.sectionOneFields {
  display: grid;
  gap: 22px 10px;
  grid-template-areas:
    "empresa empresa empresa empresa empresa empresa"
    "codigo codigo tipo tipo especifico especifico"
    "descricao descricao descricao descricao descricao descricao"
    "categoria categoria categoria categoria categoria categoria"
    "ncm ncm ncm cest cest cest"
    "descricaoNcm descricaoNcm descricaoNcm descricaoNcm descricaoNcm descricaoNcm"
    "ean ean eanTrib eanTrib codFiscal codFiscal";
}

.empresaSelectContainer {
  grid-area: empresa;
}

.codigoInputContainer {
  grid-area: codigo;
}

.tipoProdutoInputContainer {
  grid-area: tipo;
}

.tipoEspecificoInputContainer {
  grid-area: especifico;
}

.descricaoInputContainer {
  grid-area: descricao;
}

.categoriaInputContainer{
  grid-area: categoria;
}

.ncmInputContainer {
  grid-area: ncm;
}

.cestInputContainer {
  grid-area: cest;
}

.descricaoNcmInputContainer {
  grid-area: descricaoNcm;
}

.eanInputContainer {
  grid-area: ean;
}

.eanTributarioInputContainer {
  grid-area: eanTrib;
}

.codigoBeneficioFiscalInputContainer {
  grid-area: codFiscal;
}

// Section 2

.sectionTwo {
  margin-top: 60px;
}

div.sectionTwoFields > div span {
  max-width: inherit;
  align-items: center;
}

div.sectionTwoFields {
  display: grid;
  gap: 22px 10px;
  grid-template-areas:
    "unidadeComercial valorComercial"
    "unidadeTributaria valorTributario"
    "pesoLiquido pesoBruto"
    "origemMaterial percentualImpostos"
    "valorTributos valorTributos"
    "utilizaTabela extensaoTabela"
    "issqn paramsIcms";
}

div.sectionThreeFields {
  margin-top: 40px;
  display: grid;
  gap: 22px 10px;
  grid-template-areas:
    "cst pis aliquotaPis"
    "cstCofins cofins aliquotaCofins";
}

.unidadeMedidaComercialContainer {
  grid-area: unidadeComercial;
}

.valorUnitarioComercialContainer {
  grid-area: valorComercial;
}

.unidadeMedidaTributariaComercial {
  grid-area: unidadeTributaria;
}

.valorUnitarioTributarioContainer {
  grid-area: valorTributario;
}

.pesoLiquidoContainer {
  grid-area: pesoLiquido;
}

.pesoBrutoContainer {
  grid-area: pesoBruto;
}

.origemMaterialContainer {
  grid-area: origemMaterial;
}

.percentualImpostosContainer {
  grid-area: percentualImpostos;
}

.extensaoTabelaIPIContainer {
  grid-area: extensaoTabela;
}

.valorTotalTributosContainer {
  grid-area: valorTributos;  
}

.utilizaTabelaContainer {
  grid-area: utilizaTabela;
}

.switchTabela {  
  display: flex;
  align-content: baseline;
}
.switchTabelaLabel { 
  margin-top: 6px;
  color: #EF4066;
  font-size: 14px;
  padding-right: 10px;
}
.extensaoTabelaContainer {  
  grid-area: extensaoTabela;
}

.issqnContainer {  
  grid-area: issqn;
}

.paramsIcmsContainer { 
  grid-area: paramsIcms;
  button {  
    float: right;
    margin: 20px 0 0 0;
  }
}

// Section 3

section.sectionThree {
  margin-top: 60px;
}

section.sectionThree h1.sectionTitle {
  margin-bottom: 10px !important;
}

.sectionSubtitle {
  font-size: 14px;
  color: #EF4066;
  font-weight: 600;
  margin: 0;
  margin-bottom: 15px;
}

// Section 4

section.sectionFour {
  margin-top: 60px;
}

section.sectionFour h1.sectionTitle {
  margin-bottom: 10px !important;
}

button.removeImageButton {
  border: 1px solid #812990 !important;
  color: #812990;
  border-radius: 3px;
  background-color: transparent;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: normal;
  cursor: pointer;
  outline: 0;
}

div.buttonArea {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
}

.downloadImageButton {
  margin-right: 7px !important;
}

div.imageContainer {
  max-height: 215px;
  border: 1px solid #812990;
  border-radius: 3px;
  width: fit-content;
  padding: 10px;
}

div.imageContainer img {
  max-height: 215px;
}

.pis {
  grid-area: pis;
}

.alicotaPis {
  grid-area: alicotaPis;
}

.cst {
  grid-area: cst;
}

.cofins {
  grid-area: cofins;
}

.aliquotaCofins {
  grid-area: aliquotaCofins;
}

.cstCofins {
  grid-area: cstCofins;
}