@use "../../../assets/styles/colors";

.emitir-nfce-form-container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.emitir-nfce-form-title {
  color: colors.$color-secondary;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.payment-value {
  margin-top: 20px;
}

.sub-payment-value {
  margin-top: 5px;
  padding: 0 20px;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #4A545B;
  }
}

.list-products-container {
  overflow-y: auto;
  // background: #000;
  padding-right: 5px;
  max-height: 350px;
  height: 100%;
}

.list-products-wrapper {
  list-style: none;
  padding: 0px;
}

.quantity-options {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.info-product {
  display: flex;
  align-items: center;
  gap: 10px;
}

.values-products-container {
  margin-top: 40px;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 30px;
}


.values-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: colors.$color-primary;
  }

  button {
    max-width: 140px;
    width: 100%;
    height: 40px;
    margin: 0px;
  }
}

.payment-section {
  margin-top: 1rem;
}

.total-products-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.total-values-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #8AC64B;
  }
}

.discount {
  h3 {
    color: colors.$color-secondary;
  }
}

.product-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 10px;

  p {
    font-size: 12px;
    line-height: 14px;

    /* Contorno dos Forms */

    // color: #C8C8C8;

  }

  button {
    outline: none;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$color-secondary;
    cursor: pointer;
  }
}

.empty-span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 20px;
  color: #4A545B;
}

.list-product-item {
  padding-top: 20px;
  list-style: none;
  border-bottom: 1px solid #812990;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #4A545B;
  }
}


.checkout-container {
  background: #F8F8F8;
  min-height: 800px;
  max-height: 1000px;
  height: 100%;
  padding: 30px;

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: colors.$color-primary;
  }
}

.buttons-pre-danfe {
  padding-top: 40px;
  position: relative;
  height: 100px;
}

.buttons-container-pre-danfe {
  display: flex;
  align-items: center;
  position: absolute;
  gap: 20px;
  top: 30px;
  right: 10px;
}

.emitir-nfce-section-title {
  color: colors.$color-secondary;
  font-size: 24px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.emitir-danfe-section-title {
  color: colors.$color-secondary;
  font-size: 24px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 2rem;
}

.nota-danfe-info-bottom {
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  gap: 20px;
  align-items: center;
}

.qr-code-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.margin-left-info {
  margin-left: 10px;
}

.info-line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 4px;

    /* Petroleo */

    color: #005E78;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    /* Petroleo */

    color: #005E78;
  }
}

.info-line-access {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 4px;

    /* Petroleo */

    color: #005E78;
  }

  span {
    margin-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    /* Petroleo */

    color: #005E78;
  }
}

.nota-nfce-container {
  background-color: #f8f8fb;
  height: min-content !important;
  border-bottom: none !important;
  padding-bottom: 40px;
}

.nota-danfe-container {
  background-color: #f8f8fb;
  height: min-content !important;
}

.info-danfe-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nota-nfce-info {
  white-space: pre-line;
  word-wrap: break-word;
  margin: 20px 0;
  color: colors.$color-primary;
  text-align: center;
  font-family: Lato Regular;
  font-size: 12px;
  float: right;
  min-height: 60px;
}

.nota-nfce-icon {
  position: relative;
  bottom: 100px;
  right: -5rem;
}

.icon-pdv {
  height: 100%;
  width: 100%;
  pointer-events: none;
  margin-top: 10px;
}

.pdv-menu-wrapper {
  padding: 10px;
  height: 100%;
}

.icon-wrapper-pdv {
  outline: none;
  border: none;
  background: none;
  // margin: 0 5px;
  padding: 2px;
  cursor: pointer;
  height: 100% !important;
  width: 30px !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nota-nfce-icon-wrapper {
  height: 1px !important;
}

.nota-nfce-table {
  color: colors.$color-primary;
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  padding: 16px;

  tr {
    height: 48px;
  }

  td {
    text-align: left;
    border-bottom: 0.5px #dfdfe2 solid;
    padding: 8px 3px;
  }

  th {
    text-align: left;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 0.5px #dfdfe2 solid;
  }
}

.pdv-container {
  background-color: colors.$color-white;
  height: max-content;
  width: max-content;
}

.pdv-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30;
  margin-top: -3.5rem;
  align-items: center;
  padding: 1rem;

}

.modal-items {
  div {
    display: flex;
    flex-direction: column;

    > label {
      margin: 0 !important;
    }

    > span {
      font-size: 14px;
      color: #4b4b4b;
    }
  }

}

.title-pdv {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #152D45;
}


.header-menu {
  position: absolute;
  right: calc(3% - 7px);
  top: 13px;
  z-index: 5;
  
  button {
    margin: 0;
  }
}

.table-sem-dados {
  color: #6c6c6c;
  display: block;
  width: 100% !important;
  padding: 20px 0;
  text-align: center;
}

.column-head-id {
  width: 12%;
  text-align: start !important;
  padding-left: 0px !important;
}

.column-numero {
  width: 12%;
}

.column-quantidade {
  width: 8%;
}

.column-td-valor {
  width: 12%;
  text-align: center !important;
}

.column-th-valor {
  width: 12%;
  text-align: center !important;
}

.column-id {
  width: 12%;
  display: table-cell;
  div {
    display: flex;
    gap: 6px;
    button {
      width: 18px;
      height: 20px;
      border: 1px solid #e8ebf1 !important;
      color: colors.$color-primary;
    }
  }
}

.column-descricao {
  width: 20%;
}

.nota-nfce-total {
  color: colors.$color-primary;
  text-align: right;
  font-weight: 600;
  margin-right: 16px !important;
  margin-bottom: 50px !important;
}

.nota-nfce-info-total-label {
  max-width: 200px !important;
  text-align: right;
}

.nota-nfce-info-total-valor {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding-right: 16px;
  text-align: right;
  color: colors.$color-primary;
}

.nota-nfce-info-total-label {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  margin-right: 2px !important;
  padding-bottom: 3px;
  text-align: left;
  color: colors.$color-primary;
}

.nota-nfce-info-desconto {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding-right: 16px;
  text-align: right;
  color: colors.$color-secondary;
}

.nota-nfce-info-soma-valor {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding: 10px 16px 0 0;
  text-align: right;
  color: colors.$color-secondary;
}

.nota-nfce-info-soma-label {
  max-width: 200px !important;
  border-bottom: 1px dashed #dfdfe2;
  padding: 10px 0 3px 0;
  margin-right: 2px !important;
  text-align: left;
  color: colors.$color-secondary;
}

.emitir-nfce-cadastro-container {
  padding: 20px;
  background-color: #f8f8fb;
  width: calc(100% - 18px) !important;
  margin: 20px auto auto auto !important;
}

.emitir-nfce-cadastro-imagem {
  height: auto;
  width: auto;
  max-width: 180px;
  max-height: 120px;
}

.emitir-nfce-field-quantidade {
  input {
    color: colors.$color-secondary !important;
  }
}

.emitir-nfce-field-desconto {
  padding-left: 6px !important;
  input {
    color: colors.$color-secondary !important;
  }
}

.emitir-nfce-field-total {
  padding-left: 6px !important;
}

.emitir-nfce-btn-adicionar {
  height: 36px;
  width: 36px;
  float: right;
}

.emitir-nfce-pagamento {
  margin-top: 30px;
}

.emitir-nfce-pagamento-label {
  color: colors.$color-primary;
  margin-bottom: 5px !important;
}

.emitir-nfce-pagamento-row {
  max-height: 40px !important;
}

.emitir-nfce-pagamento-text {
  color: #6c6c6c;
  padding-left: 12px !important;
  margin-top: 8px !important;
}

.emitir-nfce-pagamento-valor {
  color: #6c6c6c;
  text-align: right;
  margin-top: 8px !important;
}

.emitir-nfce-pagamento-divider {
  border-bottom: 1px dashed #dfdfe2;
}

.emitir-nfce-submit {
  margin-top: 30px;
  margin-right: 8px;
  display: flex;
  justify-content: flex-end;
}

.nfce-config-info-container {
  margin-top: 30px;
}
