@use "../../assets/styles/colors";

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}
.inputLabel {
  position: relative;
  margin-bottom: 0.5rem;
  color: #ef4066;
  font-size: 14px;
  line-height: 25px;
}

.input {
  background-color: transparent;
  position: relative;
  z-index: 1;
  background-color: transparent;
  cursor: pointer;

  ::placeholder {
    color: #6c6c6c;
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
    font-size: 14px;
    font-style: italic;
  }
}

.requiredItem {
  margin: 0 0.1rem;
}

.input {
  width: 100%;
  outline: none;
  padding: 0.6rem !important;
  border: 1px solid #c8c8c8 !important;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.inputContainer {
  position: relative;
  display: flex;
}
