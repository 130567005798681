.container-row-relatorio-notas {
  gap: 32px;

  & > div:first-of-type {
    width: 190px;
    direction: ltr;
  }
}

.container-header-relatorio-notas,
.container-row-relatorio-notas {
  display: flex;
  .name-column {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 85px;
    padding-left: 32px;
  }
}

.container-relatorio-notas {
  &.MuiGrid-container {
    overflow-x: scroll;
  }
  .accordion-component {
    min-width: 100%;
    width: fit-content;
    .MuiAccordionSummary-root {
      display: block;
    }
  }
  .cell-table {
    width: 85px;
    &:first-of-type {
      width: 154px;
    }
  }
  .MuiTableContainer-root {
    min-height: 0;
  }
}

.height-auto {
  .session-container {
    height: fit-content;
  }
}

.tootip-relatorio-nota {
  div.place-top::before {
    height: 10px;
  }
}

@media (min-width: 1100px) {
  .max-width-for-page.table-no-header {
    width: calc(97vw - 220px);
  }
}
