@use '../../assets/styles/colors';

.hide-content {
  display: none;
}
.steps {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 25px 20px;
}

.stepAvanca,
.stepVolta {
  display: inline-block;
  position: relative;
  margin: 2px;
}

.stepBtn {
  position: absolute;
  bottom: 1px;
  right: 1.5%;
}

.stepIndice,
.stepCorpo {
  display: inline-grid;
}

.stepIndice {
  width: 30%;
}

.stepIndice a {
  border: none;
  padding: 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  color: #6c6c6c;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  overflow-wrap: break-word;
  white-space: nowrap;
}

.stepIndice a span {
  margin-right: 2px;
}

.stepIndice a:focus,
.stepIndice a:active,
.active {
  outline: none;
  border-left: 5px solid colors.$color-primary !important;
  color: colors.$color-primary !important;
}

.stepCorpo {
  width: 65%;
  padding-bottom: 30px;
  height: 100%;
  margin-left: 35px;
}

.m-30 {
  margin-bottom: 30px;
}

.showCursorPointer {
  cursor: pointer;
}

.showCursorDefault {
  cursor: default;
}