.lista-fornecedores-session-container {
  position: relative;
  background-color: #ffffff;
  height: 100%;
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 3px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
}
.lista-fornecedores-session-container-header {
  display: flex;
  height: auto;
  align-items: center;
  margin-bottom: 0.5rem;
  position: relative;
}
.marginLeftAuto{
  margin-left: auto;
}