@use "../../assets/fonts/fonts";

.container-grafico-home {
  .grafico-container-titulo {
    padding-left: 30px;
    span{
        display: block;
    }
    .grafico-subtitulo {
      color: #000;
      font-family: fonts.$font-regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .grafico-titulo {
      font-family: fonts.$font-regular;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    @media(max-width: 1100px){
        padding-left: 0;
    }
  }
}
