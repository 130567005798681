@use "../../../assets/styles/colors";
@use "../../../assets/fonts/fonts";

.button-area {
  margin-bottom: 10vh;
}

.wrapperCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleHistoricoWrapper {
  display: flex;
  margin-top: 2vh;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  > span {
    font-size: 20px;
    font-weight: 700;
  }
}

.interacaoContainer {
  max-width: 770px;
  margin: 0 auto !important;
}

.interacoesWrapper {
  position: relative;
  min-height: 20vh;
  height: auto;
  width: 100%;
  background-color: #f5f5f5;
  border-bottom: 1px solid #000;
  margin-top: 2vh;
  margin-bottom: 4vh;
}

.interacoes-title {
  font-size: 14px;
  font-weight: 700;
  color: #1d1d1d;
}

.interacoes-mensagem {
  font-weight: 500;
  font-size: 14px;
  color: colors.$color-text-secundary;
}

.interacoes:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #000;
  right: 51px;
  bottom: -20px;
}

.interacoes:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 21px solid transparent;
  border-right: 21px solid transparent;
  border-top: 21px solid #f5f5f5;
  border-bottom: 21px solid transparent;
  right: 50px;
  bottom: -40px;
}

.label-mensagem {
  position: relative;
  margin-bottom: 0.5rem;
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 25px;
}

.textarea-mensagem {
  font-family: "Roboto";
  font-size: 14px;
  color: colors.$color-text-primary;
  font-weight: 400;
}

.textarea-mensagem::placeholder {
  font-family: "Roboto";
  font-size: 14px;
  font-style: italic;
  color: colors.$color-text-primary;
  font-weight: 400;
}

.proposta-container {
  padding: 20px 0px 20px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.proposta-container span {
  font-family: fonts.$font-regular;
  font-weight: 700;
  font-size: 14px;
}

.switch-wrapper {
  padding: 3px;
  background-color: #ececec;
  align-items: center;
  border-radius: 3px;
  margin-left: 12px;
}
