.info {
  width: 100%;
  padding: 10px;

  span {
    font-size: 14px;
    line-height: 17px;
    color: #4a545b;
    height: 40px;
  }

  margin: 5px 0px 20px 0px;
  border-bottom: 2px dashed rgba(5, 59, 89, 0.24);
}

.session-container-form {
  .select-container {
    > div:nth-child(2) {
      border: 1px solid #c8c8c8;
      border-radius: 4px;
    }
  }
}

.title-services {
  padding: 10px;

  span {
    font-size: 16px;
    line-height: 19px;
    color: #812990;
  }
}

.container-input-switch {
  display: flex;
  align-items: center;
  color: #EF4066;
  font-size: 14px;
}

.directBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
}

.btnRemove {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    width: 150px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 32px;
    margin-right: 10px;
  }
}

.modal-items {
  display: flex;
  flex-direction: column;
}
.modal-items > label {
  margin: 0 !important;
}

.modal-items > span {
  font-size: 14px;
  color: #4b4b4b;
}

.dialog-modal-integracao{
  div.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded{
    height: auto !important;
  }
}