.category-register-container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
}

.category-register-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "empresa empresa"
    "grupo tipo"
    "nome conta";
}

.button-area {
  display: flex;
  justify-content: flex-end;
}

.category-register-form-empresa-input {
  grid-area: empresa;
}

.category-register-form-grupo-input {
  grid-area: grupo;
}

.category-register-form-tipo-input {
  grid-area: tipo;
}

.category-register-form-nome-input {
  grid-area: nome;
}

.category-register-form-conta-input {
  grid-area: conta;
}

.required-text {
  margin-top: 20px;
}
