@use "../../assets/styles/colors";

:root {
  --logo-header: url("../../assets/img/logo001.svg");
  --logo-header-width: 180px;
  --logo-header-height: 72px;

  --logo-header-bg-width: 220px;
  --logo-header-bg-height: 4rem;
}
.header {
  position: relative;
  z-index: 3;
  width: 100vw;
  height: 64px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 220px auto;
}

.no-header {
  position: relative;
  z-index: 3;
  width: 300px;
  height: 64px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 220px auto;
}

.header-secondary {
  z-index: 1;
}

.header-logo-link {
  outline: none;
}

.header-logo-bg {
  box-sizing: border-box;
  width: var(--logo-header-bg-width);
  height: var(--logo-header-bg-height);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var();
}
.header-logo {
  width: var(--logo-header-width);
  height: var(--logo-header-height);
  margin-top: 9px;
  background-image: var(--logo-header);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.02);
    transition: all 300ms ease-in-out;
  }
}
.no-header {
  height: 0;
  width: 0;
}
.header-bar {
  width: 100%;
  display: flex;
}
.with-button {
  justify-content: space-between;
}
.header-bar-container-title-page {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 2%;
}
.header-bar-container-align {
  margin-left: 16px;
  width: max-content;
  max-width: 100%;
}
.header-bar-title-page {
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
  color: colors.$color-primary;
}
.header-bar-title-page-special-text {
  color: colors.$color-secondary;
}
.flat-default {
  color: colors.$color-white;
  background-color: colors.$color-secondary;
}
.secondary-outline {
  border: 1px solid #6c6c6c !important;
  color: #6c6c6c;
  border-radius: 3px;
}
.secondary-outline:hover {
  border: 1px solid #1d1d1d !important;
  color: #1d1d1d;
}

@media (max-width: 1100px){
  .responsive-grid-contabil-container{
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 100%;
    gap: 17px;
    height: 84px;
  }
  .responsive-grid-contabil-button{
    width: 100%;
  }
}