
@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.headerTitle{
    font-family: fonts.$font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    /* Laranja */
    
    color: colors.$color-secondary;
}


.dependenteFontDecline {
    color: colors.$color-text-secundary;
    font-weight: 700;
    font-size: 14px;
  }
  
  .dependenteFontAccept {
    color: colors.$color-warning;
    font-weight: 700;
    font-size: 14px;
    margin-left: -12px !important;
  }
  
  .helperText {
    font-family: fonts.$font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    /* Azul Escuro */
    max-width: 100%;
    overflow-wrap: anywhere;

    color: colors.$color-secondary;
    display: flex;
    span{
      // display: flex;
      margin-right: 10px;
      padding: 5px;
      cursor: pointer;
      white-space: nowrap;
      // overflow-wrap: break-word;
    }
  }

  .form{
    width: 100%;
  }

  .subSection{
    margin: 0 auto;
    max-width: 770px;
    width: 100%;
    gap: 22px 10px;
  }

  .pageSection{
    background: colors.$color-white;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  
  .inputLabel {
    position: relative;
    margin-bottom: 0.5rem;
    color: colors.$color-text-primary !important;
    font-size: 14px;
    line-height: 25px;
  }