.filter-button-area {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  button {
    margin: 0 !important;
    margin-right: 8px !important;
    margin-top: 30px !important;
    width: 150px !important;
  }
}

.custom-filter-container {
  background: #000;
}

.container-info-header {
  padding: 20px 100px;
}

.box-data {
  background: #F8F8FB;
  border-radius: 6px;
  padding: 15px;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #EF4066;
  }
}

.text-data-notafiscal {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #EF4066;
  display: flex;
  align-items: center;

  p {
    margin-left: 5px;
    font-weight: 400;
  }

}

.content-data {
  margin-top: 20px;
  padding: 10px;
  display: grid;
  grid-template-columns: 4fr 2fr;
}
