@use "../../assets/styles/colors";


.inputContainer {
  display       : flex;
  flex-direction: row;
  width         : 100%;
  position      : relative;
  align-items   : center;
}

label {
  position     : relative;
  margin-bottom: .5rem;
  color        : #EF4066;
  font-size    : 14px;
  line-height  : 25px;
}

input[type=checkbox]+label {
  display       : block;
  // margin     : 0.2em;
  margin-left   : 20px;
  width         : calc(100% - 78%);
  width         : calc(100% - 78%);
  cursor        : pointer;
  padding       : 0.2em;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox]+label:before {
  content       : "";
  border        : 2px solid #D5D5D5;
  border-radius : 0.2em;
  display       : inline-block;
  position      : absolute;
  left          : -20px;
  width         : .8rem;
  height        : .8rem;
  padding-left  : 0.1em;
  padding-bottom: 0.1em;
  margin-top    : .3rem;
  margin-right  : 0.2em;
  vertical-align: bottom;
  transition    : .2s;
}

input[type=checkbox]+label:active:before {
  transform: scale(0);
}

input[type=checkbox]:checked+label:before {
  background-color: colors.$color-secondary;
  border-color    : colors.$color-secondary;
  color           : #fff;
}

input[type=checkbox]:disabled+label:before {
  transform   : scale(1);
  border-color: #aaa;
}

input[type=checkbox]:checked:disabled+label:before {
  transform       : scale(1);
  background-color: #fccf9e;
  border-color    : #fccf9e;
}

input[type=checkbox]+label:after {
  content       : "\2714";
  border-radius : 0.2em;
  display       : inline-block;
  left          : -20px;
  top           : 0px;
  position      : absolute;
  width         : .8rem;
  height        : .8rem;
  padding-left  : 0px;
  padding-bottom: 0.1em;
  margin-top    : .2rem;
  margin-left   : 0.3em;
  vertical-align: bottom;
  color         : white;
  transition    : .2s;
}


input[type=checkbox]+label {
  display      : block;
  // margin    : 0.2em;
  // width  : calc(100% - 78%);
  width        : 100%;
  cursor       : pointer;
  padding      : 0.2em;
}

input[type=checkbox] {
  display: none;
}

// input[type=checkbox]+label:before {
//   content       : "";
//   border        : 2px solid #D5D5D5;
//   border-radius : 0.2em;
//   display       : inline-block;
//   position      : absolute;
//   right         : 0;
//   width         : .8rem;
//   height        : .8rem;
//   padding-left  : 0.1em;
//   padding-bottom: 0.1em;
//   margin-top    : .3rem;
//   margin-right  : 0.2em;
//   vertical-align: bottom;
//   transition    : .2s;
// }

// input[type=checkbox]+label:active:before {
//   transform: scale(0);
// }

// input[type=checkbox]:checked+label:before {
//   background-color: colors.$color-secondary;
//   border-color    : colors.$color-secondary;
//   color           : #fff;
// }

// input[type=checkbox]:disabled+label:before {
//   transform   : scale(1);
//   border-color: #aaa;
// }

// input[type=checkbox]:checked:disabled+label:before {
//   transform       : scale(1);
//   background-color: #fccf9e;
//   border-color    : #fccf9e;
// }

// input[type=checkbox]+label:after {
//   content       : "\2714";
//   border-radius : 0.2em;
//   display       : inline-block;
//   right         : 0;
//   top           : 0px;
//   position      : absolute;
//   width         : .8rem;
//   height        : .8rem;
//   padding-left  : 0px;
//   padding-bottom: 0.1em;
//   margin-top    : .2rem;
//   margin-right  : 0.35em;
//   vertical-align: bottom;
//   color         : white;
//   transition    : .2s;
// }

/* RADIO */


input[type=radio]+label {
  display: block;
  margin : 0.2em;
  width  : calc(100% - 78%);
  cursor : pointer;
  padding: 0.2em;
}

input[type=radio] {
  display: none;
}

input[type=radio]+label:before {
  content       : "";
  border        : 2px solid #D5D5D5;
  border-radius : 0.2em;
  display       : inline-block;
  position      : absolute;
  right         : 0;
  width         : .8rem;
  height        : .8rem;
  padding-left  : 0.1em;
  padding-bottom: 0.1em;
  margin-top    : .3rem;
  margin-right  : 0.2em;
  vertical-align: bottom;
  transition    : .2s;
}

input[type=radio]+label:active:before {
  transform: scale(0);
}

input[type=radio]:checked+label:before {
  background-color: colors.$color-secondary;
  border-color    : colors.$color-secondary;
  color           : #fff;
}

input[type=radio]:disabled+label:before {
  transform   : scale(1);
  border-color: #aaa;
}

input[type=radio]:checked:disabled+label:before {
  transform       : scale(1);
  background-color: #fccf9e;
  border-color    : #fccf9e;
}

input[type=radio]+label:after {
  content       : "\2714";
  border-radius : 0.2em;
  display       : inline-block;
  right         : 0;
  top           : 0px;
  position      : absolute;
  width         : .8rem;
  height        : .8rem;
  padding-left  : 0px;
  padding-bottom: 0.1em;
  margin-top    : .2rem;
  margin-right  : 0.35em;
  vertical-align: bottom;
  color         : white;
  transition    : .2s;
}