@use "../../assets/styles/_colors.scss";
@use '../../assets/fonts/fonts';

.paper {
  min-width: 400;
  min-height: 178px;
  text-align: left;
  align-items: center;
}
.buttonContent {
  padding-bottom: 16px !important;
}

.titleConfirmDialog {
  font-size: 16px;
  font-family: fonts.$font-regular;
  color: colors.$color-primary;
  font-weight: 600;
  letter-spacing: 0.9px;
}

.btnSize {
  min-width: 120px !important;
  height: 32px !important;
}

.confirmDescription {
  font-family: fonts.$font-regular;
  font-size: 14px;
  color: colors.$color-text-primary;
  white-space: pre-wrap;
  word-break: keep-all;
}

.iconConfirm {
  color: colors.$color-primary;
  padding: 0;
  font-family: fonts.$font-regular;
}

.custom-dialog-content{
  padding-top: 40px !important;
  padding-bottom: 30px !important;
}

@media(max-width: 1100px){
  .dialog-modal-historico{
    .MuiDialog-paper{
      margin: 10px;
    }
    .MuiDialogTitle-root{
      max-width: 80%;
    }
  }
}