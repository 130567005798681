.form-table {
  max-width: 770px;
}

.container {
  display: flex;
  justify-content: center;
  margin: 10px 0 20px 0 !important;
  position: relative;
}

.chat:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  border-bottom: 20px solid white;
  right: 31px;
  top: -40px;
}

.chat:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid transparent;
  right: 50%;
  top: -18px;
}
.table-no-header {
  z-index: 1;
}
