.solicitacao-servico-container {
  padding: 54px 0px !important;
  height: auto !important;
}

.containerService {
  display: flex;
  max-width: 770px;
  margin: 0 auto;
}

.containerServiceList {
  height: auto !important;
  margin-top: 28px !important;
  padding: 58px 0 !important;
}

.gridXs4 {
  flex-grow: 0;
  max-width: 33.3333% !important;
  flex-basis: 33.3333% !important;
}

.btnAddService {
  max-width: 160px;
  width: 100%;
  max-height: 32px;
  height: auto;
  margin: 0;
}

.serviceTable {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.titleServices {
  color: #053b59;
  font-size: 16px;
  font-weight: 600;
}

.contratar-button-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-top: 25px;

  button {
    margin: 0 !important;
  }
}

.solicitacao-servico-contratar-button-area {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  button {
    margin: 0 !important;
    margin-top: 24px !important;
  }
}

.solicitacaoServico__statusInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e8ebf1;
  border-radius: 3px;
  max-height: 38px;
  height: 100%;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;

  .label {
    margin-bottom: 0.5rem;
    line-height: 17px;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    max-height: 38px;
    min-height: 38px;
    height: 100%;
    outline: 0;
    padding: 0.6rem;
    border: 0;
    cursor: default;
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }

  & > .icon {
    position: absolute;
    right: 0;
  }
}

.colorWarning{
  color: #EF4066;
}