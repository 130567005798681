@use "../../../assets/styles/colors";
@use "../../../assets/fonts/fonts";

.text-tipo-fornecedor-for {
  font-family: fonts.$font-regular;
  font-size: 14px;
  color: colors.$color-secondary;
  font-style: bold;
  margin-left: 20px;
  line-height: 17px;
}

.input-cli-for-area {
  display: flex;
  flex-direction: row;
}

