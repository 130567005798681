@use "../../assets/styles/colors";

.modal-content {
  max-width: 650px;
  height: auto;
  padding-top: 24px !important;
  padding-bottom: 32px !important;
  background-color: #f8f8f8 !important;
  overflow-x: hidden;
}

.modal-title-area {
  background-color: #ffff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
}

.modal-items {
  display: flex;
  flex-direction: column;
}
.modal-items > label {
  margin: 0 !important;
}

.modal-items > span {
  font-size: 14px;
  color: #4b4b4b;
}

.items-right {
  text-align: left;
}

.modal-verFatura {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 46px;
}

.btn-verFatura {
  border: 1px solid colors.$color-secondary !important;
  color: colors.$color-secondary;
  background-color: white;
  box-sizing: border-box;
  padding: 1em 2em;
  margin: auto 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  border-radius: 3px;
  width: 150px;
  background-color: #f8f8f8 !important;
}

.btn-verFatura:hover {
  border: 1px solid #EF4066 !important;
  color: #EF4066;
}

.button-flex-end{
  margin-left: auto;
  margin-right: 0;
}