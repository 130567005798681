@use "../../assets/styles/colors";

.navbar-btn-menu-visible {  
  margin-left: 180px;
  padding: 10px 0;
  z-index: 10;
  position: fixed;
  width: min-content;
  color: colors.$color-primary !important;
} 

.navbar-btn-menu-hidden {  
  justify-content: flex-end;
  margin-left: 16px !important;
  padding: 10px 0;
  z-index: 10;
  position: relative;
  left: 0;
  width: min-content;
  color: colors.$color-primary !important;
}