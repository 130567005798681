.cadastro-servicos-container {
  padding: 30px 15% !important;
}

.servico-switchDestaque {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-left: 4px;
}

.servico-switchOption {
  margin-right: 12px;
}

.servico-destaque {
  color: #EF4066;
  font-size: 14px;
  padding-top: 15px; 
  padding-bottom: 20px;
}

.container-select-fit-content .react-select__control{
  min-width: fit-content;
}

.container-item-retencao{
  width: fit-content;
  margin: auto;
}