.form-edit-profile {
  margin: 0 auto;
  max-width: 927px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .container-input-imagem {
    padding-left: 0 !important;

    .container-pl0 {
      padding-left: 0 !important;
    }
  }
}
