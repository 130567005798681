@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.container-default {
  width: 100vw;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 220px auto;
  grid-template-rows: 64px auto;
}

.container-relative {
  position: relative;
}

.container-default-component {
  grid-column-start: 2;
  grid-row-start: 2;
  padding: 1% 3% 0 2%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x:hidden;
}

.container-iframe {
  height: 100vh;
}

.container-public-page{
  width: 100vw;
  min-height: 100vh;
  padding-inline: 150px;
  margin-bottom: 150px;

  .footer-img{
    width: 150px;
  }

  .container-footer-img{
    padding-top: 2rem;
  }

  .title-public-page{
    font-family: fonts.$font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;

    margin-bottom: 1rem;
    color: #152D45;
    text-align: center;

  }
}

.title-public-page{
  @media(max-width: 1100px){
    font-family: "Lato Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.container-default-small {   
  width: auto;
  overflow-x: auto;
}
.container-footer-img {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.footer-img {
  width: 100px;
  height: 50px;
  background-image: url("../../assets/img/logo001.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.header-mobile {  
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.icon-menu-mobile {  
  justify-content: flex-end;
  padding-right: 30px !important;
  padding-top: 30px !important;
  color: colors.$color-primary !important;
}

.img-menu-mobile {  
  margin: 20px;
  width: 130px;
}

.secondary-container {
  display: inline-grid;
}

.component {
  min-height: 90vh;
}

@media (max-width: 625px) {
  .container-default-component {
    padding: 1% 2%;
  }
}

.whatsapp-menu-button.btn{
  margin: 0.55rem 15px 0;
  padding: 0;
  border: none!important;
  background-color: rgba(27,213,96,.9)!important;
  cursor: pointer;
  width: fit-content;
  max-width: 100%;
  position: fixed;
  bottom: 24px;
  right: 12px;

  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  text-align: center;
  border-radius: 0.358rem;

  color: #fff!important;

  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 1;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,background 0s,border 0s;

  padding-inline: 15px;
  z-index: 10;
}