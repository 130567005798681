.certificado-register-container {
  height: auto !important;
  padding: 2.4rem 0 3.4rem !important;
}

.certificado-register-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "empresa empresa empresa empresa empresa empresa"
    "file file file file file file"
    "senha senha senha data data data"
    "download download download download download download";

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.certificado-register-form-empresa-input {
  grid-area: empresa;
}

.certificado-register-form-file-input {
  grid-area: file;
}

.certificado-register-form-senha-input {
  grid-area: senha;
}

.certificado-register-form-data-input {
  grid-area: data;
  .label {
    margin-bottom: 0.4rem !important;
  }
}

.certificado-register-form-download {
  grid-area: download;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.certificado-register-form-download .primary-outline {
  padding: 0.5rem 2.5rem !important;
}

.certificado-register-form-empresa-input-fill {
  background: #f8f8fb !important;
  width: 100%;
  outline: none;
  padding: 0.6rem;
  border: 1px solid #e8ebf1;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  z-index: 1;
  cursor: default;
}

.required-text {
  margin: 0 !important;
}
