@use '../../../../assets/styles/colors';

.vale-transporte-container {
  background-color: colors.$color-white;
  height: max-content;
  width: max-content;
}

.vale-transporte-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;
  padding: 1rem;
  padding-right: 25px;
  padding-left: 25px;
}

.title-vale-transporte {
  font-weight: 600;
  font-size: 16px;
  color: colors.$color-secondary;
}
