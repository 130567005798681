.session-container {
  &.session-container-height-auto {
    height: auto;
    padding-bottom: 40px;
  }
}

.session-container {
  &.session-container-height-auto {
    height: auto;
    padding-bottom: 40px;
  }
  &.session-periods {
    padding: 38px 80px 60px;
    .container-periods {
      .periods-title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #152d45;
        margin-bottom: 15px;
      }
      .period {
        display: grid;
        grid-template-columns: repeat(5, 1fr) 2.5fr;
        column-gap: 10px;
        align-items: baseline;
        .h-100 {
          height: 100%;
          display: flex;
          flex-direction: column;
          .data-content {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
        .item {
          // background-color: black;
          // color: #fff;
          .label {
            .data-content {
              font-style: italic;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;

              color: #4a545b;
            }
          }
        }
      }
    }

    @media (max-width: 1100px) {
      padding-left: 1rem;
      padding-right: 1rem;

      .font-title {
        padding: 0 !important;
      }
    }
  }
}

div.warning-text {
  margin-top: 20px;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;


  a {
    text-decoration: none;
    color: #4a545b;
    span {
      text-decoration: underline;
    }
  }
}
