@use "../../assets/styles/colors";
@use "../../assets/styles/typography";

.container-info {
  .text-info-component {
    font-size: typography.$font-size-md;
    color: colors.$color-gray;
    line-height: 16.8px;
    a {
      text-decoration: none;
      color: colors.$color-gray;
      span {
        text-decoration: underline;
      }
    }
  }

  .info-icon {
    color: colors.$color-primary;
    margin-top: 3px;
    font-size: typography.$font-size-lg;
  }

  @media (max-width: 767px) {
    &.MuiGrid-container {
      flex-wrap: nowrap;
      margin-top: 16px;
    }
  }
}
