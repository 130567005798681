@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.info {
  width: 100%;
  padding: 10px;

  span {
    font-size: 14px;
    line-height: 17px;
    color: #4a545b;
    height: 40px;
  }

  margin: 5px 0px 20px 0px;
  border-bottom: 2px dashed rgba(5, 59, 89, 0.24);
}

.session-container-form {
  .select-container {
    > div:nth-child(2) {
      border: 1px solid #c8c8c8;
      border-radius: 4px;
    }
  }
}

.title-services {
  padding: 10px;

  span {
    font-size: 16px;
    line-height: 19px;
    color: #812990;
  }
}

.directBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
}

.btnRemove {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    width: 150px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 32px;
    margin-right: 10px;
  }
}

.aviso-inativar-dda {
  color: colors.$color-danger;
  font-family: fonts.$font-regular;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.container-status-dda.MuiGrid-item {
  margin-top: 8px;

  span[class^="status-"] {
    color: colors.$color-white;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    font-family: fonts.$font-regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 17px;
    padding: 4px 8px;

    &.status-confirmado {
      background-color: colors.$color-green;
    }
    &.status-processando {
      background-color: colors.$color-blue;
    }
    &.status-recusado {
      background-color: colors.$color-danger;
    }
  }
}
