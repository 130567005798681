@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 3px !important;
  box-sizing: border-box;
  border: 1px solid #c8c8c8;
  height: 100%;
  max-height: 38px;
  min-height: 38px;
}
.inputLabel {
  position: relative;
  display: flex;
  align-items: center;
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 21.5px;
  margin-bottom: 0.4rem;
}
.inputLabelCeu {
  position: relative;
  display: flex;
  align-items: center;
  font-family: fonts.$font-bold !important;
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 21.5px;
  font-weight: 700;
  margin-bottom: 0.4rem;
}
.inputCeu {
  background-color: transparent;
  font-family: fonts.$font-regular !important;
  color: #6c6c6c;
  line-height: 14.5px;
  font-weight: 400;
  font-size: 12px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  height: max-content;
  min-height: 38px;
  max-height: 38px;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0.6rem;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input {
  background-color: transparent;
  position: relative;
  z-index: 1;
  cursor: pointer;
  height: max-content;
  min-height: 38px;
  max-height: 38px;
  height: 100%;
}

.input::placeholder {
  font-size: 14px;
  font-style: italic;
  color: #6c6c6c;
}

.inputNumber {
  background-color: transparent;
  position: relative;
  z-index: 1;
  cursor: pointer;
  height: max-content;
  max-height: 36.1px;
  text-align: right;
  border: none;
  width: 100%;
  padding: 8px 30px 8px 0;
  height: 100%;
}

.inputMoney {
  background-color: transparent;
  position: relative;
  z-index: 1;
  cursor: pointer;
  height: max-content;
  max-height: 36.1px;
  text-align: right;
  border: none;
  width: 100%;
  padding: 8px 0;
  height: 100%;
}

.inputMoney::placeholder {
  font-size: 14px;
  font-style: italic;
  color: #6c6c6c;
}

.inputMoney:focus {
  outline: none;
}

.inputNumber::placeholder {
  font-size: 14px;
  font-style: italic;
  color: #6c6c6c;
}

.inputNumber:focus {
  outline: none;
}

.input::placeholder {
  font-size: 14px;
  font-style: italic;
  color: #6c6c6c;
}

.input::placeholder {
  font-size: 14px;
  font-style: italic;
  color: #6c6c6c;
}

.input::placeholder {
  font-size: 14px;
  font-style: italic;
  color: #6c6c6c;
}

.inputUnderline {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  border-bottom: 1px solid colors.$color-primary;
}

.suffixLabel {
  margin: 0 16px;
  display: flex;

  span {
    color: colors.$color-secondary;
  }
}
.suffixIconShare {
  margin-top: 4px;
}
.suffixIconShare:hover {
  cursor: pointer;
}
.suffixIconInfo {
  color: #812990 !important;
  font-size: 16px !important;
  margin: 3px 16px 0 0 !important;
}
.inputHint {
  line-height: 17px;
  font-size: 14px;
  font-style: italic;
  color: #6c6c6c;
  background-color: #ffffff;
  position: absolute;
  display: inline-table;
  top: 2.2rem;
  left: 0.6rem;
  max-width: 10rem;
  width: auto;
  z-index: 1;
  transition: all 0.1s;
}

.inputHintHide {
  opacity: 0;
}
.inputAnimate {
  top: 1.9rem !important;
  z-index: 3 !important;
  font-style: normal;
  color: #424242;
  opacity: 1 !important;
}
.input {
  width: 100%;
  outline: none;
  padding: 0.6rem;
  border: 0;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.error {
  border: 1px solid #ff4757;
  width: 100%;
  outline: none;
  padding: 0.6rem;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  position: absolute;
  left: calc(100% - 2rem);
  top: 7px;
}

.iconArrow {
  position: absolute;
  left: calc(100% - 2rem);
  top: 9px;
}

.infoButton {
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;
  display: flex;

  .suffixIconShare {
    margin-top: 0 !important;
  }
}
