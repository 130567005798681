@use "../../assets/styles/colors";

.show-container-header {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E8EBF1;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #EF4066;
  }

  color: #EF4066;
}

.box-container-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
}

.show-info-container {
  display: flex;
  align-items: center;

  span {
    margin-right: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #4B4B4B;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: #4B4B4B;
  }
}


