.p-horizontal {
  width: 65%;
  margin: auto;
}

.zero-margin {
  margin: 0;
}

.label {
  margin: 0 0px 8px 4px;
}
