.teste-button-container{
    display: grid;
    width: 35% ;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
}
.teste-button-container button{
    margin: 1rem;
}
.teste-button-container button:nth-child(even){
    grid-column-start: 2;
}

.input-state-force{
    border: 1px solid #E8EBF1 !important;
}