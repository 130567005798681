@use "../../../assets/styles/colors";
@use "../../../assets/fonts/fonts";

.containerVersoes {
  .containerPdf {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    width: fit-content;
    margin-left: auto;
    max-width: 100%;
    max-height: 964px;
    overflow-y: scroll;

    .loadingPdf{
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background: #E2E2E2;
    }

    &::-webkit-scrollbar-thumb {
      background: #808080;
    }

    @media (max-width: 768px) {
      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }
  }

  .columnVersoes {
    align-items: center;
    gap: 10px;

    .cardVersao {
      border-radius: 3px;
      border: 1px solid #c8c8c8;
      background: colors.$color-white;
      padding: 10px 15px;
      width: 160px;
      max-width: 100%;

      color: #333;
      font-family: fonts.$font-regular;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14.5px; /* 120.833% */
      gap: 16px;
      display: flex;
      flex-direction: column;

      .mesVersao {
        border-right: solid 1px #bdbdbd;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .containerNomeVersao {
        display: flex;
        align-items: center;
        justify-content: center;
        .nomeVersao {
          color: #0019ff;
          text-decoration: underline;
          cursor: pointer;
        }
        @media (max-width: 768px) {
          display: block;
          text-align: center;
        }
      }
      .verAnteriores {
        color: #0019ff;
        text-decoration: underline;
        cursor: pointer;
        margin-top: 10px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .botaoDownload {
      width: 160px;
      margin-top: 10px;
      .btn-success {
        max-width: 100%;
        margin: auto 0;
      }
    }

    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }
}
