.contractWrapper iframe {
  top: 0 !important;
  left: 0 !important;
  max-height: unset !important;
}
#bot,
#yv-nps-frame {
  display: none !important;
  visibility: hidden !important;
}
