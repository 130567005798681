.teste-modal-container {
  display: grid;
  width: 35%;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}
.teste-modal-container button {
  margin: 1rem;
}
.teste-modal-container button:nth-child(even) {
  grid-column-start: 2;
}
