@use "../../../assets/styles/colors";

.text-tipo-gerentes-for {
  font-family: "Lato Regular";
  font-size: 14px;
  color: colors.$color-primary;
  font-style: bold;
  margin-left: 20px;
  line-height: 17px;
}

.input-cli-for-area {
  display: flex;
  flex-direction: row;
}
