@use '../../../assets/fonts/fonts';

.large-input {
  border: solid 1px #c8c8c8;
  margin-top: 8px;
  height: 70px;
  width: 99%;
  font-family: fonts.$font-regular;
  font-size: 14px;
  padding: 8px;
  resize: vertical;
}

.large-input:focus {
  outline: none;
}

.large-input.textarea-100{
  width: 100%;
  max-width: 100%;
}