.escolhe-planos-container {
  display: flex;
  background-color: #fff;
  height: 100%;
  padding: 1rem;
  margin: 0 0 1rem 0;
  border-radius: 3px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.05);
}

.escolhe-planos-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  > h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    margin-bottom: 31px;
  }
}

.escolhe-planos-content {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 1240px) {
    padding-bottom: 120px;
  }
}
