.cadastrar-preAdmissao-form {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.circle-image {
  width: 133px;
  height: 133px;
  border-radius: 50%;
  display: flex;
  background: #f8f8fb;
}

.gridXs4 {
  flex-grow: 0;
  max-width: 33.33%;
  flex-basis: 33.33%;
}

.preAdmissaoCustomCheck .MuiFormControlLabel-label {
  font-size: 14px;
  color: #EF4066;
}

.preAdmissaoCustomCheck .MuiFormControlLabel-labelPlacementStart {
  margin-left: 0;
}

.preAdmissaoCheckboxContainer {
  label.MuiFormControlLabel-root {
    margin: 0 !important;
  }
}

label#thumbnail {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
