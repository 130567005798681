.configuracoes-boleto-form {
  margin: 0 auto !important;
  max-width: 770px !important;
  width: 100% !important;
  display: grid;
  gap: 22px 10px;
  grid-template-areas:
    "switch logo"
    "input1 input2";
}

.config-boletos-form-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.config-boletos-form-fields {
}

.switch-boletos-config {
  grid-area: switch;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.switch-boletos-config > span {
  font-size: 14px;
  font-weight: bold;
  color: #812990;
}

.bb-logo-container {
  grid-area: logo;
  text-align: right;
}

.config-boletos-vencimento {
  grid-area: input1;
}

.config-boletos-texto {
  grid-area: input2;
}
