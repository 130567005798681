@use "../../assets/fonts/fonts";

.grafico {
  min-height: 376px;
}

.align-right {
  display: flex;
  flex-direction: column;
  width: 30%;
  right: 2%;
  top: 2%;
  position: absolute;
  max-width: 300px;
}

.home-column-reverse {
  flex-direction: column-reverse;
}

.session-wrapper {
  display: grid;
  grid-template-columns: 3fr 1.5fr;
  gap: 20px;

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.graphic-container {
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.03), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(1, 1, 1, 0.04);
  border-radius: 15px;
  padding: 20px 30px;
  width: 100%;
  height: 600px;

  .empty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    span {
      display: block;
      font-family: fonts.$font-regular;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: #4a545b;
    }

    @media (max-width: 800px) {
      justify-content: center;
      flex-direction: column-reverse;
      span {
        text-align: center;
      }
    }
  }

  .graphic {
    width: 95%;

    @media (max-width: 800px) {
      width: 98%;
    }
  }

  .session-graphic {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
  }

  .graphic-header {
    div {
      p {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #ef4066;
      }

      span {
        padding: 25px;

        h4 {
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          font-weight: normal;
        }

        p {
          font-size: 24px;
          line-height: 29px;
          color: #676767;
          font-weight: normal;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    height: auto;
  }
}

.session-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.account {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(121, 121, 121, 0.1);
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 20px 30px;

  > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ef4066;
    margin-bottom: 20px;
  }

  span {
    padding: 10px 10px 10px 10px;

    h4 {
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      font-weight: normal;
    }

    span {
      font-size: 24px;
      line-height: 29px;
      color: #676767;
      font-weight: normal;
      min-width: 150px;
      display: inline-block;
    }

    img {
      margin-top: 12px;
      position: absolute;
      display: inline-block;
    }
  }
}

.list {
  flex: 1;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(121, 121, 121, 0.1);
  border-radius: 15px;
  padding: 20px 30px;

  > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ef4066;
    margin-bottom: 20px;
  }

  .list-container {
    overflow-y: scroll;
    max-height: 310px;
  }

  .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    div {
      margin-left: 13px;
    }

    p {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: #812990;
    }

    span {
      font-weight: normal;
      font-size: 10px;
      line-height: 18px;
      /* identical to box height, or 180% */

      color: #858585;
    }
  }

  .list-empty {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      margin-top: 20px;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      text-align: center;

      color: #4b4b4b;
    }
  }
}

.bottom-session {
  background: linear-gradient(178.18deg, #ef4066 -13.56%, #876370 158.3%);
  border: 1px solid #ffffff;
  border-radius: 10px;
  height: 218px;
  margin-top: 20px;
  padding: 40px 60px;
  position: relative;
  display: flex;

  a {
    position: absolute;
    outline: none;
    background: #ffffff;
    border-radius: 100px;
    color: #812990;
    height: 28px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    border: none;
    right: 40px;
    cursor: pointer;
  }

  .info {
    padding: 20px;
    border-bottom: none !important;
    h3 {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;

      color: #ffffff;
    }

    span {
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;

      color: #ffffff;
    }

    @media (max-width: 800px) {
      padding: 0px;

      h3 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      span {
        font-size: 13px;
      }

      img {
        max-width: 25%;
      }
    }
  }

  .image-detail {
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: 800px) {
      display: none;
    }
  }

  @media (max-width: 800px) {
    padding: 20px;

    a {
      bottom: 10px;
      left: 170px;
    }
  }
}

.home__account-box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ef4066;
  }
}
