.navbar-footer {
  display: flex;
  flex-direction: column;
  color: #6c6c6c;
  padding: 25px 0;
}
.navbar-footer-social-network {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  margin-bottom: 18px;
}

.navbar-footer-social-network > svg {
  cursor: pointer;
}

.navbar-footer-description {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
}
