@use "../../assets/styles/colors";

.banco-container {
  height: 60% !important;
}
.container-conciliacao {
  max-width: 75% !important;
  margin: auto;
  padding: 30px 0;
  float: center;

  &.atualizar-integracao {
    max-width: 90% !important;
  }
}

.card-banco {
  margin: 20px;
  padding: 10px;
  color: white;
  background: linear-gradient(77.31deg, #ef4066 3.62%, #1c375a 99.59%);
  border: 1px solid #edf1f7;
  border-radius: 8px;
  height: fit-content;
}

.card-extrato {
  display: flex;
  margin-top: 46px !important;
  padding: 0 15px;
  height: 108px;
  color: #4b4b4b;
  font-weight: 600;
  background-color: #f8f8fb;
  border: 1px lavender solid;
  border-radius: 8px;
  font-size: 12px;
}
.grid-extrato {
  margin: 0 !important;
}

.btn-conciliar {
  margin-top: 80px;
}

.card-buscar {
  margin: 46px 16px 0 0 !important;
}

.card-extrato-contabil {
  padding: 0 15px;
  margin: 0px !important;
  height: 108px;
  color: #4b4b4b;
  font-weight: 600;
  background-color: #f8f8fb;
  border: 1px lavender solid;
  border-radius: 8px;
  font-size: 12px;
}

.header-card-contabil {
  margin-top: 25px !important;
}
.icon-wrapper {
  margin-right: 8px !important;
}
.font-saldo-banco {
  font-weight: 600;
  font-size: 16px;
  color: colors.$color-secondary;
  text-align: end;
  padding-right: 15px;
}

.font-nome-banco {
  font-weight: 600;
}
.font-vermelho {
  color: #bb0000;
}
.font-verde {
  color: #219653;
}

.font-primary {
  color: colors.$color-secondary;
}
.text-importar {
  margin: 30px 0 !important;
  text-align: center;
}

.text {
  color: #ef4066;
  font-weight: 600;
  font-size: 16px;
}

.ofx-header {
  background-color: #f8f8fb;
  margin: 0px 0 10px 0 !important;
  padding: 16px 20px;
}

.ofx-column-title {
  margin-top: 60px !important;
  text-align: center;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 6px;
  background-color: colors.$color-secondary !important;
}

.extrato-container {
  padding: 20px !important;
}

.conciliacao-pagination {
  margin-top: 30px !important;
  height: 50px;
}

.session-container.filter-component-container.filter-conciliacao {
  box-shadow: none;
  padding-top: 0 !important;
  width: 97% !important;
}

.session-container.extrato-container {
  padding-top: 40px !important;
}

.session-container.pagina-conciliacao {
  height: fit-content;

  .session-container-header.container-conciliacao {
    padding-bottom: 0;
  }
}
