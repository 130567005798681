.cadastro-certidao-form-container {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  display: grid !important;
  gap: 22px 10px;
  grid-template-areas:
    "certidao orgaoemissor"
    "abrangencia status"
    "emissao vencimento"
    "empresa empresa"
    "anexo anexo";
}

.cadastro-certidao-certidao-select {
  grid-area: certidao;
}

.cadastro-certidao-orgaoemissor-select {
  grid-area: orgaoemissor;
}

.cadastro-certidao-abrangencia-select {
  grid-area: abrangencia;
}

.cadastro-certidao-status-select {
  grid-area: status;
}

.cadastro-certidao-emissao-input {
  grid-area: emissao;
}

.cadastro-certidao-vencimento-input {
  grid-area: vencimento;
}

.cadastro-certidao-empresa-select {
  grid-area: empresa;
}

.cadastro-certidao-anexo-input {
  grid-area: anexo;
}

.emitir-nf-form-submit-button {
  display: flex;
  flex-direction: row-reverse;
}

.required-text {
  margin-top: 20px;
}
