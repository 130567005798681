$color-primary: #ef4066;
$color-secondary: #812990;
$color-success: #8ac64b;
$color-danger: #b00;
$color-warning: #ef4066;
$color-text-primary: #ef4066;
$color-text-secundary: #6c6c6c;
$color-white: #fff;
$color-dark-gray: #4b4b4b;
$color-green: #27ae60;
$color-orange: #ff6200;
$color-gray: #4a545b;
$color-blue: #0a2346;

:export {
  colorPrimary: $color-primary;
  colorSecondary: $color-secondary;
  colorSuccess: $color-success;
  colorDanger: $color-danger;
  colorWarning: $color-warning;
  colorTextPrimary: $color-text-primary;
  colorTextSecundary: $color-text-secundary;
  colorWhite: $color-white;
  colorDarkGray: $color-dark-gray;
  colorGreen: $color-green;
  colorOrange: $color-orange;
  colorGray: $color-gray;
  colorBlue: $color-blue;
}