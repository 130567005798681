.login-loader-div{
    outline: none;
    cursor: default;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.login-loader{
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin{
    to { transform: rotate(360deg);}
}