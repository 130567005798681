.modal-buscar-lancamento {
  .content-buscar-conciliacao {
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 10px !important;
    padding: 0 20px;
  }

  .btn-margin {
    margin: 10px 16px 30px 0;
  }

  .linha-verde {
    color: #2abb7f;
    font-weight: bold;
  }
}
