@use "../../assets/styles/colors";
@use "../../assets/fonts/fonts";

.formSelectInput > div {
  width: 100% !important;
  min-width: 200px;
  outline: 0 !important;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;
  max-height: 38px;
  height: 100%;
  font-size: 14px;
}
.formSelectInput > div {
  width: 100% !important;
  min-width: 200px;
  outline: 0 !important;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;
  max-height: 38px;
  height: 100%;
  font-family: fonts.$font-regular !important;
  color: #6c6c6c;
  line-height: 14.5px;
  font-weight: 400;
  font-size: 12px;
}

.tableSelectInput > div {
  border: none !important;
  border-bottom: 1px solid #e8ebf1 !important;
  width: 100% !important;
  min-width: 200px;
  outline: 0 !important;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;
  max-height: 38px;
  height: 100%;
  font-size: 14px;
}

.selectUnderline > div {
  border: none !important;
  border-bottom: 1px solid colors.$color-primary !important;
  width: 100% !important;
  outline: 0 !important;
  box-sizing: border-box;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none !important;
  max-height: 38px;
  height: 100%;
}

.labelContainer {
  margin-bottom: 0.4rem;
}

.selectLabel {
  color: colors.$color-primary;
  font-size: 14px;
  line-height: 21.5px;
}
.selectLabelCeu {
  color: colors.$color-primary;
  font-size: 14px;
  font-family: fonts.$font-bold !important;
  line-height: 21.5px;
  font-weight: 700;
}

.requiredItem {
  margin: 0 0.1rem;
}
